.creation-product {
    flex: 1 1;
    font-size: 12px;
}

.creation-product__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: 2px solid #F2F3F5;
}

.creation-product__header-title {
    display: flex;
    gap: 20px;
    align-items: center;
    color: #6B7A99;
    font-weight: 700;
}

.creation-product__header-title-img {
    width: 20px;
    height: 20px;
}   

.creation-product__header-title-text {
    white-space: nowrap;
}

.creation-product__header-btn-wrap {
    display: flex;
    align-items: center;
    gap: 20px;
}

.creation-product__header-btn {
    background: #4C6FFF;
    border-radius: 6px;
    padding: 12px 20px;
    color: #fff;
    margin-left: auto;
    transition: .3s;
}

.creation-product__header-btn:hover {
    background: #6B7A99;
}

.creation-product__header-btn-close {
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 6px;
    padding: 9px 15px;
    transition: .3s;
}

.creation-product__header-btn-close:hover {
    background: #e0ede8;
}

.creation-product__header-btn-close-img {
    display: inline-block;
    width: 25px;
    height: 25px;
    transform: rotate(45deg);
}

.creation-product__header-btn-close-text {
    color: #7D8FB3;
    font-size: 14px;
}

.creation-product--wrap {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    align-items: flex-start;
    padding-left: 15px;
    padding-top: 15px;
    color: #828282;
}

.creation-product__create-wrap {
    margin-bottom: 25px;
}

.creation-product__create-title {
    position: relative;
    margin-bottom: 8px;
}

.creation-product__create-name-input {
    width: 100%;
    max-width: 500px;
}

.creation-product__create-name-sub-title {
    margin-top: 3px;
}

.creation-product__create-category-select {
    max-width: 285px;
    width: 100%;
}

.creation-product__create-price-input {
    max-width: 180px;
    width: 100%;
}

.creation-product__btn-cancel-new-price-wrap {
    display: flex;
    gap: 15px;
    align-items: center;
    flex-wrap: wrap;
}

.creation-product__btn-cancel-new-price {
    background: #4C6FFF;
    border-radius: 6px;
    padding: 12px 20px;
    color: #fff;
    transition: .3s;
}

.creation-product__btn-cancel-new-price:hover {
    background: #6B7A99;
}   

.creation-product__create-details-input {
    max-width: 500px;
    width: 100%;
}

.creation-product__btn-cancel-sizes-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.creation-product__create-sizes-btn {
    background: #4C6FFF;
    border-radius: 6px;
    /* padding: 12px 20px; */
    color: #fff;
    width: 49px;
    height: 42px;
    transition: .3s;
}

.creation-product__create-sizes-btn:hover {
    background: #6B7A99;
}

.creation-product__create-result-sizes-wrap {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 15px;
}

.creation-product__create-result-sizes {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    background: #EBEEF2;
    border-radius: 100px;
    padding: 3px 0 3px 7px;
}

.creation-product__create-result-sizes-text {

}

.creation-product__create-result-sizes-btn {
    width: 14px;
    height: 13px;
    cursor: pointer;
}

.creation-product__btn-cancel-colors-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.creation-product__create-colors-btn {
    background: #4C6FFF;
    border-radius: 6px;
    color: #fff;
    width: 49px;
    height: 42px;
    transition: .3s;
}

.creation-product__create-colors-btn:hover {
    background: #6B7A99;
}

.creation-product__result-colors-wrap {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 15px;
}

.creation-product__result-colors {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    background: #EBEEF2;
    border-radius: 100px;
    padding-left: 7px;
}

.creation-product__result-colors-btn {
    width: 14px;
    height: 13px;
    cursor: pointer;
}

.creation-product__create-img--wrap {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    flex: 1 1;
}

.creation-product__create-img-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border: 3px dashed #E5E5E5;
    border-radius: 6px;
    max-width: 262px;
    height: 230px;
    text-align: center;
    padding: 5px 10px;
}

.creation-product__create-img-text-wrap {
    position: relative;
}

.creation-product__create-img-link-upload-input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    top: 0;
}

.creation-product__create-img-link-upload-lable {
    color: rgba(76, 111, 255, 1);
    cursor: pointer;
}

.creation-product__create-title-name,
.creation-product__create-title-price,
.creation-product__create-title-category {
    display: inline-block;
}

.creation-product__create-title-name::after,
.creation-product__create-title-price::after,
.creation-product__create-title-category::after {
    content: '*';
    position: absolute;
    top: 0;
    right: -10px;
    color: #E31E24;
}

.creation-product__warning-text {
    position: relative;
    padding-left: 9px;
    font-size: 15px;
    margin-bottom: 15px;
}

.creation-product__warning-text::before {
    content: '*';
    position: absolute;
    top: 0;
    left: 0;
    color: #E31E24;
}

.creation-product__section-img-wrap {
    display: flex;
    gap: 7px; 
    flex-wrap: wrap;
}

.creation-product__create-list-wrap {
    overflow: auto;
    margin-top: 13px;
    margin-bottom: 13px;
    display: flex;
    flex-wrap: wrap;
    gap: 7px;
}

.creation-product__create-list {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    border-radius: 8px;
    border: 1px solid #E5E5E5;
}

.creation-product__create-list-img {
    width: 200px;
    aspect-ratio: 9/12;
    object-fit: contain;
    border-top: 1px solid #E5E5E5;
}

.creation-product__create-list-btn {
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 50%;
    padding: 3px;
    transition: .3s;
}

.creation-product__create-list-btn:hover {
    background-color: rgb(161, 157, 157);
}

@media (min-width: 576px) {
    .creation-product {
        font-size: 16px;
    }
    .creation-product--wrap {
        padding-top: 40px;
    }
    .creation-product__header {
        padding-left: 70px;
        padding-right: 70px;
    }
    .creation-product__create-colors-btn {
        margin-top: 42px;
    }
}