.more-analytics1 {
    padding-top: 5px;
    padding-bottom: 15px;
}

.more-analytics1--wrpa {

}

.more-analytics1__error {
    text-align: center;
    margin-top: 100px;
    font-size: 20px;
    font-weight: 500;
    color: #6D7580;
}

/* ------------------------- */

.more-analytics1__customers {
    display: flex;
    flex-direction: column;
    margin-top: 120px;
    margin-bottom: 30px;
}

.more-analytics1__customers-title-wrap {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    border-bottom: 1px solid rgba(194, 201, 209, 1);
    padding-bottom: 10px;
    margin-bottom: 15px;
}

.more-analytics1__customers-title-name {
    font-size: 14px;
    font-weight: 700;
    color: #000;
    min-width: 200px;    
    font-weight: 500;
}

/* ------------------ */
.more-analytics1__purchases {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.more-analytics1__purchases-all {
    display: flex;
    gap: 20px;
    align-items: center;
    box-shadow: 0px 18px 32px 0px rgba(208, 210, 218, 0.15);
    padding: 10px;
    border-radius: 8px;
}

.more-analytics1__purchases-all-img-wrap {
    width: 65px;
    height: 65px;
    background: #F4F7FE;
    border-radius: 50%;
    padding: 12px;
}

.more-analytics1__purchases-all-img {
    opacity: .7;
}

.more-analytics1__purchases-all-text-wrap {
}

.more-analytics1__purchases-all-text {
    color: #A3AED0;
    font-size: 14px;
}

.more-analytics1__purchases-all-text-count-wrap {
    display: flex;
    gap: 5px;
    align-items: center;
    margin-top: 5px;
    font-size: 14px;
}

.more-analytics1__purchases-all-text-count {
    font-weight: 700;
    color: #1B2559;
}

/* ------------------ */

.more-analytics1__analytics-error {
    margin-top: 40px;
    text-align: center;
    font-size: 18px;
}

/* -------profitYear------- */
.more-analytics1__profit-year-wrap {
    padding: 4px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.more-analytics1__profit-year {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.more-analytics1__profit-year-title {
    font-size: 14px;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
}

.more-analytics1__profit-year-sub-title {
    font-size: 12px;
    font-weight: 700;
    text-align: center;
}

.more-analytics1__profit-year-graph {
    width: 100%;
    height: 420px;
}
.more-analytics1__profit-year-graph-canvas {
    width: 100% !important;
}

.more-analytics1__profit-year-tabl {
    display: flex;
    gap: 5px;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    max-height: 230px;
    overflow: auto;
    font-size: 12px;
}

.more-analytics1__profit-year-tabl-item {
    display: flex;
    flex-wrap: wrap;
    width: 49%;
    gap: 5px;
    padding: 2px 4px;
    overflow: auto;
}

.more-analytics1__profit-year-tabl-item-info {
}
/* ------------------ */
.more-analytics1__visitors-wrap {
    /* padding: 4px; */
    /* margin-top: 20px;
    margin-bottom: 20px; */
    margin-top: 20px;
    width: 100%;
    height: 540px;
    box-shadow: 0px 18px 32px 0px rgba(208, 210, 218, 0.15);
    padding: 20px;
    border-radius: 8px;
}

.more-analytics1__visitors {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.more-analytics1__visitors-title {
    font-size: 14px;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
}

.more-analytics1__visitors-info {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.more-analytics1__visitors-info-title {
    font-size: 12px;
    font-weight: 700;
}

.more-analytics1__visitors-graph {
    width: 100%;
    height: 420px;
}
.more-analytics1__visitors-graph-canvas {
    width: 100% !important;
}

.more-analytics1__visitors-tabl {
    display: flex;
    gap: 5px;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    height: 306px;
    overflow: auto;
    font-size: 12px;
}

.more-analytics1__visitors-tabl-item {
    padding: 2px 10px;
}

/* ---------------- */

.more-analytics1__accordion-result {
    width: 100%;
    margin-top: 15px !important;
    border: 1px solid #e3dede;
}

.more-analytics1__top-product {
    max-width: 100%;
    margin-top: 50px;
}

.more-analytics1__top-product-title {
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.more-analytics1__top-product-cards-wrap {
    display: flex;
    width: 100%;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: start;
}

@media (min-width: 576px) {
    .more-analytics1 {
        padding-top: 25px;
    }
    .more-analytics1__customers-card {
        flex-wrap: nowrap;
    }
    .more-analytics1__purchases {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .more-analytics1__purchases-all-text {
        font-size: 16px;
    }
    .more-analytics1__customers-title-name {
        font-size: 18px;
    }
    .more-analytics1__purchases-all-text-count-wrap {
        font-size: 16px;
    }
    .more-analytics1__profit-year-wrap {
        border: 5px solid #e3dede;
        padding: 10px;
    }
    .more-analytics1__profit-year-title {
        font-size: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .more-analytics1__profit-year-sub-title {
        font-size: 18px;
    }
    .more-analytics1__profit-year-tabl {
        font-size: 16px;
    }
    .more-analytics1__profit-year-tabl-item {
        gap: 15px;
        padding: 2px 10px;
    }
    .more-analytics1__visitors-wrap {
        padding: 10px;
    }
    .more-analytics1__visitors-title {
        font-size: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .more-analytics1__visitors-info-title {
        font-size: 18px;
    }
    .more-analytics1__visitors-tabl {
        font-size: 16px;
    }
    .more-analytics1__top-product-title {
        font-size: 18px;
    }
}
@media (min-width: 768px) {
    .more-analytics1__order-status {
        flex-direction: row;
    }
}