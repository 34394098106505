.search-btn {
    position: relative;
    max-width: 194px;
}

.search-btn__input {
    width: 100%;
    display: block;
    border-bottom: 1px solid var(--colorHeaderText);
    padding-bottom: 4px;
    color: var(--colorHeaderText);
}

.drop-down-menu__sub-categories--wrap .search-btn__input {
    border-bottom: 1px solid #000000;
    color: #000000;
}

.search-btn__input:focus {
    color: var(--colorHeaderText);
}

.drop-down-menu__sub-categories--wrap .search-btn__input:focus {
    color: #000000;
}

input.search-btn__input::placeholder {
    color: var(--colorHeaderText);
}

.drop-down-menu__sub-categories--wrap input.search-btn__input::placeholder {
    color: #000000;
}

.search-btn__img {
    position: absolute;
    right: 0;
    top: -3px;
    width: 20px;
    height: 20px;
    fill: var(--colorHeaderText);
}

.drop-down-menu__sub-categories--wrap .search-btn__img  {
    fill: #000000;
}