.pricing-view-wrap {
    background: #f6f8fa;
    min-height: calc(100vh - 260px);
    margin-top: 64px;
    margin-bottom: 40px;
    padding-top: 100px;
}

.pricing-view {
    font-size: 12px;
}

.pricing-view__title {
    font-size: 26px;
    margin-bottom: 20px;
    text-align: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.pricing-view__sub-title {
    font-size: 20px;
    margin-bottom: 20px;
    text-align: center;
    color: #8390aa;
}

.pricing-view__month-btn-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

.pricing-view__month-btn {
    border: 1px solid #ebebeb;
    background-color: #ffffff;
    padding: 10px 25px;
    min-width:  140px;
    transition: .3s;
}

.pricing-view__month-btn--active {
    background: #04befe;
    color: #fff;
}

.pricing-view__month-btn-month {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    
}

.pricing-view__month-btn-year {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;

}

.pricing-view__items {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    justify-content: center;
    margin-bottom: 30px;
}

.pricing-view__item {
    min-width: 300px;
    width: 400px;
    height: auto;
    border-width: 1px;
    border-radius: 8px;
    background-color: #ffffff;
    background-position: center center;
    border-color: #ebebeb;
    border-style: solid;
    box-shadow: 0px 40px 60px -20px rgba(131,144,170,0.1);
    padding: 40px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.pricing-view__item-base {
    background: #2cd65a;
    color: #fff;
}

.pricing-view__item--wrap {
}

.pricing-view__item-title {
    font-size: 26px;
    text-align: center;
    margin-bottom: 20px;
}

.pricing-view__item-sub-title {
    font-size: 20px;
    text-align: center;
    margin-bottom: 15px;
    color: #8390aa;
    min-height: 54px;
}

.pricing-view__item-sub-title-base {
    min-height: 54px;
    color: #fff;
}

.pricing-view__item-price-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pricing-view__item-price {
    font-size: 64px;
}

.pricing-view__item-price-currency {
    font-size: 30px;
    margin-bottom: 20px;
}

.pricing-view__item-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 20px;
}

.pricing-view__item-info-text-wrap {
    display: flex;
    gap: 5px;
    align-items: baseline;
}

.pricing-view__item-info-text-img {
    width: 24px;
    height: 24px;
}

.pricing-view__item-info-text {
    font-size: 20px;
}

.pricing-view__item-btn {
    padding: 15px 30px;
    width: 100%;
    border-radius: 8px;
    font-size: 20px;
    background: #fff;
    color: #000;
    border: 1px solid #ebebeb;
    transition: .3s;
}

.pricing-view__item-btn:hover {
    background: #102245;
    color: #fff;
}

.pricing-view__info {
    padding-top: 50px;
    padding-bottom: 50px;
    background: #fff;
}

.pricing-view__info-title {
    font-size: 42px;
    text-align: center;
    margin-bottom: 50px;
}

.pricing-view__info-items {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
}

.pricing-view__info-item {
    width: 220px;
}

.pricing-view__info-item-img {
    width: 56px;
    height: 56px;
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
}

.pricing-view__info-item-text {
    font-size: 18px;
    text-align: center;
}

.question__question {
    padding-top: 60px;
    padding-bottom: 100px;
}

.question__question-title {
    font-size: 40px;
    text-align: center;
    margin-bottom: 40px;
}

.question__question-items{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.question__question-item {
    max-width: 400px;
    min-width: 300px;
    width: 100%;
}

.question__question-item-title {
    text-align: center;
    margin-bottom: 24px;
    font-weight: 700;
}

.question__question-item-text {
    color: #8390aa;
    font-size: 18px;
}

@media (min-width: 576px) {
    .pricing-view {
        font-size: 12px;
    }
    .pricing-view__title {
        font-size: 32px;
        width: 49%;
    }
}

@media (min-width: 768px) {
    .pricing-view__title {
        font-size: 40px;
        width: 49%;
    }
}