.user-message {
    height: calc(100vh - 115px);
}

.user-message--wrap {
    height: 100%;
}

.user-message__title-wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-left: 15px;
    gap: 10px;
    font-weight: 700;
}

.user-message__title {
    color: #6B7A99;
    display: flex;
    align-items: center;
}

.user-message__title-products-img {
    width: 28px;
    height: 28px;
    margin-right: 20px;
}

.user-message__title-btn {
    display: flex;
    align-items: center;
    color: #7D8FB3;
}

.user-message__title-btn-img {
    width: 25px;
    height: 25px;
    transform: rotate(45deg);
}

.user-message__contacts-wrap {
    display: flex;
    border-top: 2px solid #F2F3F5;
}

.user-message__contacts-list--block {
    display: none;
}

.user-message__contacts-list {
    width: 100%;
    min-width: 200px;
    height: calc(100vh - 172px);
    border-right: 1px solid rgba(46, 91, 255, 0.08);
    border-left: 1px solid rgba(46, 91, 255, 0.08);
    margin-left: 30px;
}

.user-message__contacts-list-item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 20px 25px;
    border-bottom: 1px solid rgba(46, 91, 255, 0.08);
    cursor: pointer;
    transition: .3s;
}

.user-message__contacts-list-item:hover {
    background: #EBEEF2;
}

.user-message__contacts-list-item--active {
    background: #EBEEF2;
}

.user-message__contacts-list-item-img {
    width: 50px;
    height: 50px;
}

.user-message__contacts-list-item-text-wrap {
    margin-bottom: 3px;
    flex: 1 1;
}

.user-message__contacts-list-item-text-name-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.user-message__contacts-list-item-text-name {
    font-weight: 600;
    font-size: 14px;
    color: #16192C;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.user-message__contacts-list-item-text-date {
    font-size: 14px;
    color: #8798AD;
}

.user-message__contacts-list-item-message-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.user-message__contacts-list-item-message {
    font-size: 14px;
    color: #8798AD;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.user-message__contacts-list-item-message-count {
    width: 22px;
    min-width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #5F41B2;
    font-size: 11px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1px;
}

.user-message__contacts-chat-wrap {
    flex: 1 1;
    display: none;
}

.user-message__contacts-chat-active {
    display: flex;
}

.user-message__contacts-chat-none {
    width: 100%;
    min-width: 250px;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 22px;
    font-weight: 600;
    color: #16192C;
    padding-left: 15px;
}

.user-message__contacts-chat {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.user-message__contacts-chat-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    box-shadow: 18px 19px 46px 2px rgba(179, 179, 179, 0.1);
}

.user-message__contacts-chat-header-name-wrap {
    display: flex;
    align-items: center;
    gap: 10px;
}

.user-message__contacts-chat-header-name-btn {
    width: 24px;
    height: 24px;
}

.user-message__contacts-chat-header-name-img {
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.user-message__contacts-chat-header-name--wrap {
    cursor: pointer;

}

.user-message__contacts-chat-header-name {
    font-size: 18px;
    color: #16192C;
}

.user-message__contacts-chat-header-phone {
    color: #6F7C8B;
    font-size: 12px;
}

.user-message__contacts-chat-header-favorite-btn {
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.user-message__contacts-chat-client-wrap {
    flex: 1 1;
    padding-bottom: 50px;
    margin-left: 15px;
}

@media (min-width: 576px) {
    .user-message__title-wrap {
        margin-top: 28px;
        margin-bottom: 28px;
        gap: 20px;
    }
}

@media (min-width: 768px) {
    .user-message__title-wrap {
        padding-left: 70px;
        padding-right: 70px;
    }
}

@media (min-width: 980px) {
    .user-message__contacts-list {
        max-width: 380px;
    }
}