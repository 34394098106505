.help-view-wrap {

}

.help-view {
    margin-top: 100px;
    padding-top: 20px;
    min-height: calc(100vh - 246px);
    margin-bottom: 100px;
}

.help-view__title {
    font-size: 28px;
    text-align: center;
    margin-bottom: 20px;
}

.help-view__sub-title {
    font-size: 18px;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 5px;
}

.help-view__accordion {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;
  }
  
  .help-view__active, .help-view__accordion:hover {
    background-color: #ccc;
  }
  
  .help-view__accordion:after {
    content: '\002B';
    color: #777;
    font-weight: bold;
    float: right;
    margin-left: 5px;
  }
  
  .help-view__active:after {
    content: "\2212";
  }
  
  .help__panel {
    padding: 0 18px;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }

  .help__panel-text {
    padding: 10px 0;
  }