
.header-landing-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(255,255,255,1);
    z-index: 100;
}

.header-landing {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 10px 10%;
}

.header-landing__logo {
    width: 30px;
}

.header-landing__drop-menu {
    position: absolute;
    top: 63px;
    right: -200%;
    width: 100%;
    height: 100vh;
    background: #74BAE8;
    transition: 1.2s;
}

.header-landing__drop-menu--active {
    right: 0;
    transition: 1.2s;
}

.header-landing__drop-menu-link-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 25vmin;
}

.header-landing__drop-menu-link {
    font-size: 32px;
    color: #ffffff;
    text-transform: uppercase;
}

.header-landing__drop-menu-contact-title {
    font-size: 22px;
    color: #bf56d7;
    font-weight: 500;
    margin-top: 15vmin;
    text-transform: uppercase;
}

.header-landing__drop-menu-link-tel {
    font-size: 22px;
    color: #ffffff;
}

.header-landing__menu-wrap {
    display: none;
}

.header-landing__btn-wrap {
    display: none;
}

.header-landing__try-now-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(92.71deg, #33CEFF 3.48%, #6884E6 45.5%, #C054FF 95.45%);
    color: white;
    border-radius: 8px;
    font-size: 14px;
    font-weight: bold;
    padding: 0px 30px;
    height: 40px;
}

.header-landing__login-btn {
    border-radius: 4px;
    font-size: 14px;
    margin-left: 20px;
    font-weight: bold;
}

.header-landing__language-selection-wrap {
    position: relative;
    cursor: pointer;
    transition: 0.3s;
    color: #000000;
}

.header-landing__language-selection {
    min-width: 33px;
    transition: 0.3s;
}

.header-landing__language-wrap {
    font-size: 22px;
    color: #ffffff;
}

.header-landing__language-selection-drop {
    display: none;
    flex-direction: column;
    gap: 5px;
    justify-content: flex-end;
    opacity: 0;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: -100;
    transition: 0.3s;
    background-color: #ffffff;
    padding: 4px;
}

.header-landing__language-selection-wrap:hover .header-landing__language-selection-drop {
    opacity: 1;
    z-index: 5;
    display: flex;
}

.header-landing__language-selection-drop-item {

}
@media (min-width: 880px) {
    .header-landing__menu-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 30px;
    }
    .header-landing__btn-wrap {
        display: flex;
        align-items: center;
        gap: 30px;
    }
}