.home-view {
}

.home-view__swiper-img {
    /* make from it hero banner */
    width: 100%;
    height: 600px;
    object-fit: cover;
    object-position: top;
}
    /* aspect-ratio: 16 / 9;
    object-fit: contain; */


.home-view__blocks-wrap {
    display: flex;
    flex-direction: column;
}

.home-view__block-order---1 {
    display: none;
}

.home-view__block-order--0 {
    order: 1;
}

.home-view__block-order--1 {
    order: 2;
}

.home-view__block-order--2 {
    order: 3;
}

.home-view__block-order--3 {
    order: 4;
}

.home-view__block-order--4 {
    order: 5;
}

.home-view__block-order--5 {
    order: 6;
}

.home-view__categories--wrap {
    margin-top: 32px;
    margin-bottom: 32px;
    order: 3;
}

.home-view__categories-title {
    font-size: 32px;
    text-align: center;
    margin-bottom: 20px;
}

.home-view__categories-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

.home-view__categories-link {
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
    width: 220px;
    border: 1px solid #E4E4E5;
    transition: .3s;
}

.home-view__categories-link:hover {
    box-shadow: 5px 5px 30px rgb(0 0 0 / 55%);
    scale: 1.03;
}

.home-view__categories-img {
    width: 100px;
    height: 100px;
}

.home-view__categories-name {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
}

.home-view__info {
    margin-top: 15px;
    margin-bottom: 15px;
}

.home-view__information-block {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    transition: .3s;
}

.home-view__information-block:hover {
    box-shadow: 5px 5px 30px rgb(0 0 0 / 50%);
}

.home-view__information-block-not-active {
    cursor: auto;
}

.home-view__information-block-title {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.home-view__information-block-text {
    padding: 0 15px;
}

.home-view__description {
    margin-top: 20px;
    margin-bottom: 20px;
}

.home-view__info-title {
    font-size: 24px;
    line-height: 1.2;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.home-view__info-text-wrap {

}

.home-view__info-text {
    font-size: 14px;
    line-height: 24px;
    max-height: 120px;
    overflow: hidden;
    transition: .3s;
}

.home-view__info-text--active {
    max-height: 100%;
}

.home-view__btn-info-wrap {
    position: relative;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-left: auto;
    cursor: pointer;
    transition: .3s;
}

.home-view__btn-info-wrap:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

.home-view__btn-info {
    position: absolute;
    top: 4px;
    left: 7px;
    width: 12px;
    height: 12px;
    border-left: 2px solid var(--colorMainText);
    border-bottom: 2px solid var(--colorMainText);
    transform: rotate(-45deg);
}

.home-view__btn-info--active {
    top: 9px;
    transform: rotate(135deg);
}

@media (min-width: 576px) {
    .home-view__images-wrap {
        flex-direction: row;
    }

    .home-view__image-link {
        width: 49%;
    }

    .home-view__info-delivery {
        gap: 15px;
    }

    .home-view__info-delivery-text {
        font-size: 16px;
    }

    .home-view__info-text {
        font-size: 16px;
    }
    .home-view__info-title {
        font-size: 40px;
    }
}
  
@media (min-width: 768px) {
    .home-view__info-delivery-img {
        width: 50px;
        height: 50px;
    }

    .home-view__info-delivery-text {
        font-size: 24px;
    }

    .home-view__info-text {
        max-height: 100%;
    }

    .home-view__btn-info-wrap {
        display: none;
    }
}