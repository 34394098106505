.personnel {
    flex: 1 1;
}

.personnel--wrap {

}

.personnel__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 21px;
    padding-bottom: 21px;
    border-bottom: 2px solid #F2F3F5;
    min-height: 85px;
}

.personnel__header-title {
    display: flex;
    gap: 20px;
    align-items: center;
    color: #6B7A99;
    font-weight: 700;
}

.personnel__header-title-img {
    width: 40px;
    height: 40px;
}

.personnel__header-title-text {
    white-space: nowrap;
}

.personnel__header-btn {
    background: #4C6FFF;
    border-radius: 6px;
    padding: 12px 20px;
    color: #fff;
    margin-left: auto;
    transition: .3s;
}

.personnel__header-btn:hover {
    background: #6B7A99;
}

.personnel__managers {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
}

.personnel__manager-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 5px;
    flex: 1 1;
}

.personnel__manager {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    padding: 18px 15px;
    border-bottom: 2px solid #F2F3F5;
    width: 100%;
}

.personnel__manager-name-wrap {
    display: flex;
    align-items: center;
    gap: 15px;
    min-width: 210px;
}

.personnel__manager-name-img {
    width: 30px;
    height: 30px;
}

.personnel__manager-name {
    color: #202020;
    font-size: 14px;
    font-weight: 500;
}

.personnel__manager-name-role {
    color: #B2AEAE;
}

.personnel__manager-email-wrap {
    min-width: 180px;
    font-size: 14px;
}

.personnel__manager-email-text {
    color: #16192C;
}

.personnel__manager-email {
    color: #4C6FFF;
}

.personnel__manager-status {
    font-size: 14px;
    padding: 2px 15px;
    border-radius: 5px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.personnel__manager-status-offline {
    border: 1px solid #DB8C28;
    color: #DB8C28;
}

.personnel__manager-status-online {
    border: 1px solid #7FC008;
    color: #7FC008;
}

.personnel__manager-role-wrap {
    display: flex;
    align-items: center;
    gap: 5px;
    min-width: 115px;
}

.personnel__manager-role-img-wrap {
    width: 20px;
    height: 22px;
    padding: 2px;
}

.personnel__manager-role-img {
    width: 100%;
    height: 100%;
}

.personnel__manager-role--manager {
    color: #FF754C;
}

.personnel__manager-role--topManager {
    color: #53A24C;
}

.personnel__manager-created-wrap {

}

.personnel__manager-created-text {
    color: #16192C;
    font-size: 14px;
}

.personnel__manager-created {
    color: #B2AEAE;
    font-size: 12px;
}

/* ------------------- */

.personnel__create {
}

.personnel__create-email {
    margin-bottom: 15px !important;
}
.personnel__create-checkbox-title {
    margin-top: 20px;
    margin-bottom: 5px;
}

.personnel__create-checkbox-wrap {
    display: flex;
    margin-bottom: 20px;
}

.personnel__create-checkbox {
    
}

.personnel__create-input-label {
    margin-right: 8px;
}

.personnel__error {
    color: red;
    font-size: 18px;
    text-align: center;
}

.personnel__edit-checkbox-wrap {
    display: flex;
}

.personnel__edit-checkbox {
    margin-left: 10px;
    margin-right: 5px;
}

.personnel__edit-input-label {
    display: block;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.personnel__manager-btn-wrap {
    display: flex;
    gap: 5px;
    margin-left: auto;
}

.personnel__manager-edit-btn {
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 50%;
    padding: 3px;
    transition: .3s;
}

.personnel__manager-edit-btn:hover {
    background-color: rgb(161, 157, 157);
}

.personnel__manager-del-btn {
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 50%;
    padding: 3px;
    transition: .3s;
}

.personnel__manager-del-btn:hover {
    background-color: rgb(161, 157, 157);
}

.personnel__manager-error {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    margin-top: 80px;
    margin-bottom: 80px;
    padding: 15px;
}

@media (min-width: 576px) {
    .personnel__header {
        padding-left: 70px;
        padding-right: 70px;
    }
}