.user-notifications {
    min-height: calc(100vh - 87px);
    flex: 1 1;
    font-size: 12px;
    padding-bottom: 55px;
}

.user-notifications--wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 142px);
}

.user-notifications__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 21px;
    margin-bottom: 21px;
    padding-left: 10px;
}

.user-notifications__header-count {
    display: flex;
    gap: 20px;
    align-items: center;
    color: #6B7A99;
    font-weight: 700;
}

.user-notifications__header-count-img {
    width: 40px;
    height: 40px;
}

.user-notifications__header-count-title {
    margin-bottom: 3px;
    white-space: nowrap;
}

.user-notifications__header-tab-wrap {
    margin-left: auto;
    margin-right: auto;
}

.user-notifications__header-tab-wrap .MuiTabs-flexContainer {
    display: flex;
    flex-direction: column;
}

.user-notifications__header-tab-wrap .MuiButtonBase-root {
    font-size: 12px;
    padding: 10px;
}

.user-notifications__filter {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 10px;
    padding: 20px 0 20px 10px;;
    margin-top: 15px;
    margin-bottom: 15px; 
    border-top: 2px solid #F2F3F5;
}

.user-notifications__filter-type {
    width: 205px;
}

.ser-notifications__filter-sort-title {

}

.user-notifications__filter-date-wrap {
    position: relative;
    display: flex;
    justify-content: end;
    flex-direction: column;
    gap: 6px;
}

.user-notifications__filter-date-btn-wrap {
    display: flex;
    justify-content: end;
    gap: 4px;
}

.user-notifications__filter-date-btn {
    border: 2px solid black;
    background-color: white;
    padding: 4px 8px;
    font-size: 14px;
    cursor: pointer;
    border-color: rgba(0, 0, 0, 0.23);
    color: #6B7A99;
    margin-top: 3px;
    display: block;
    transition: .3s;
}

.user-notifications__filter-date-btn:hover {
    background-color: rgb(161, 157, 157);
    color: #fff;
}

.user-notifications__filter-date {
    display: flex;
    align-items: end;
    justify-content: end;
    gap: 5px;
    min-width: 185px;
    cursor: pointer;
    color: #6B7A99;
}

.user-notifications__filter-date-title {
    
}

.user-notifications__filter-date-select {

}

.user-notifications__filter-date-drop {
    position: absolute;
    top: -1500px;
    right: 0;
    z-index: -2;
    border: 1px solid;
}

.user-notifications__filter-date-drop-active {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 5;
}

.user-notifications__items {
    padding-left: 15px;
}

.user-notifications__item {
    padding:10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    box-shadow: 2px 2px 8px rgb(0 0 0 / 52%);
    gap: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
    cursor: pointer;
    border-radius: 16px;
    transition: .3s;
}

.user-notifications__item:hover {
    box-shadow: 5px 4px 6px rgb(0 0 0 / 92%);
}

.user-notifications__item-stars {
    width: 25px;
    height: 25px;
    flex: 0 0 auto;
    cursor: pointer;
    fill: #c3cad9;
    transition: .3s;
}

.user-notifications__item-stars-is-favorite {
    fill: rgb(250, 15, 15);
}

.user-notifications__item-stars:hover {
    fill: rgb(250, 15, 15);
}

.user-notifications__item-name {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    width: 55px;
    height: 50px;
    font-weight: 700;
    font-size: 28px;
    border-radius: 50%;
}

.user-notifications__item-name--c {
    background: #007aff36;
}

.user-notifications__item-name--s {
    background: rgb(157 158 7 / 25%);
}

.user-notifications__item-name-img {
    width: 25px;
    height: 25px;
}

.user-notifications__item-text-wrap {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    overflow: hidden;
    margin-right: auto;
}

.user-notifications__item-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 200px;
    color:  #6F7C8B;
}

.user-notifications__item-status-wrap {
    display: flex;
    gap: 9px;
}

.user-notifications__item-btn-wrap {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: auto;
}

.user-notifications__item-del-btn-wrap {

}

.user-notifications__item-btn {
    width: 41px;
    height: 41px;
    cursor: pointer;
    border-radius: 50%;
    padding: 3px;
    transition: .3s;
}

.user-notifications__item-btn-del {

}

.user-notifications__item-btn-del--hover {
    display: none;
}

.user-notifications__item-del-btn-wrap:hover .user-notifications__item-btn-del {
    display: none;
}
.user-notifications__item-del-btn-wrap:hover .user-notifications__item-btn-del--hover {
    display: block;
}

.user-notifications__item-btn-del:disabled,
.user-notifications__item-btn-del--hover:disabled {
    opacity: .5;
}

.user-notifications__error-text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    margin-top: 70px;
    margin-bottom: 70px;
}

@media (min-width: 400px) {
    .user-notifications__filter {
        flex-direction: row;
        align-items: center;
    } 
    .user-notifications__header-tab-wrap .MuiTabs-flexContainer {
        flex-direction: row;
    }
}

@media (min-width: 576px) {
    .user-notifications {
        font-size: 16px;
    }
    .user-notifications__item-name-wrap {
        flex: 0 0 auto;
    }
    .user-notifications__header {
        padding-left: 15px;
    }
    .user-notifications__filter {
        padding: 30px 0 30px 15px;;
    }
    .user-notifications__error-text {
        font-size: 25px;
    }
}

@media (min-width: 768px) {
    .user-notifications__header {
        padding-left: 70px;
        padding-right: 70px;
    }

    .user-notifications__header-tab-wrap .MuiButtonBase-root {
        font-size: 16px;
    }
}