.blog-view-wrap {
    position: relative;
}

.blog-view__img {
    position: fixed;
    top: 64px;
    left: 0;
    height: 500px;
    object-position: top;
    z-index: -1;
    opacity: .9;
}

.blog-view {
    margin-top: 64px;
    min-height: calc(100vh - 260px);
}

.blog-view__title {
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    color: #fff;
    background: #0000005d;
    padding: 0 18px;
}

.blog-view__sub-title {
    padding: 15px 20px;
    background: #ffffff;
    font-size: 20px;
    font-weight: 500;
}

.blog-view__text {
    padding: 0 20px;
    background: #ffffff;
    line-height: 30px;
}

@media (min-width: 576px) {
    .blog-view__title {
        font-size: 42px;
    }
}

@media (min-width: 768px) {
    .blog-view__text {
        line-height: 36px;
    }
}