
.footer-landing-wrap {
   width: 100%;
   padding-right: 20px;
   padding-left: 20px;
   padding-top: 45px;
   padding-bottom: 40px;
   background: #ffffff;
}

.footer-landing__top {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: space-between;
}

.footer-landing__product {
    text-align: start;
    margin-top: 20px;
}

.footer-landing__product-title {
    color: #102245;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
}

.footer-landing__product-linck-wrap {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 10px;
}

.footer-landing__product-linck-li {
    color: #8390aa;
}

.footer-landing__contact {
    margin-top: 20px;
    text-align: start;
}

.footer-landing__contact-title {
    color: #102245;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
}

.footer-landing__contact-tel {
    display: block;
    margin-bottom: 10px;
    margin-top: 10px;
}

.footer-landing__contact-mail {
    display: block;
    margin-bottom: 10px;
}

.footer-landing__social-wrap {
    margin-top: 20px;
    text-align: start;
}

.footer-landing__social-title {
    color: #102245;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
}

.footer-landing__social-mail {
    color: #102245;
    min-width: 200px;
    border-bottom: 1px solid #102245;
    padding-bottom: 4px;
}

input.footer-landing__social-mail::placeholder {
    color: #102245;
}

.footer-landing__social-btn {
    padding: 9px 11px;
    background-color: #102245;
}

.footer-landing__social-link-wrap {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 15px;
}

.footer-landing__social-link {
    width: 25px;
    height: 25px;
}

.footer-landing__bottom_row {
    display: flex;
    margin-top: 80px;
    justify-content: space-between;
}

.footer-landing__bottom {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 15px 0;
}

.footer-landing__bottom a {
    margin-left: 10px;
    font-size: 12px;
    margin-top: 10px;
    margin-right: 10px;
}

.footer-landing__bottom-text {
    text-align: center;
    font-size: 12px;
    color: var(--colorFooterText);
}

.footer-landing__bottom-link {
    color: #065bea;
}

@media (max-width: 800px) {
    .footer-landing__bottom_row {
        flex-direction: column;  
        align-items: start;  
    }

    .footer-landing__bottom {
        flex-direction: row;
        flex-wrap: wrap;  
    }
}

@media (min-width: 576px) {
    .footer-landing__top {
        flex-direction: row;
        flex-wrap: wrap;
    }

    .footer-landing__product {
        width: 47%;
    }
    
    .footer-landing__contact {
        width: 47%;
    }

    .footer-landing__social-wrap {
        width: 100%;
    }
}

@media (min-width: 768px) {
    .footer-landing__bottom {
        flex-direction: row;
    } 

    .footer-landing__top {
        flex-wrap: nowrap;
    }

    .footer-landing__product {
        width: 100%;
    }
    
    .footer-landing__contact {
        width: 100%;
    }

    .footer-landing__social-wrap {
        width: 100%;
    }
}