
.read-notifications {
    min-height: calc(100vh - 85px);
    padding-top: 30px;
    flex: 1 1;
    color: #344054;
}

.read-notifications--wrap {

}

.read-notifications__title-wrap {
    
}

.read-notifications__btn-back {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #7D8FB3;
    font-size: 14px;
    font-weight: 400;
}

.read-notifications__btn-back-img {
    width: 20px;
    height: 20px;
}

.read-notifications__btn-back::before {
    content: "\2190" !important;
    position: absolute;
    top: 50%;
    left: 6px;
    transform: translateY(-50%);
    scale: 1.3;
}

.read-notifications__btn-back:hover {
    background: #0d48ac;
    color: white;
}

.read-notifications__title {
    text-align: center;
    margin-bottom: 15px;
    font-size: 20px;
}

.read-notifications__creation-time-title {
    margin-top: 20px;
    margin-bottom: 20px;
}

.read-notifications__text {
    text-align: center;
    font-size: 20px;
}

.read-notifications__comment {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 18px;
}

.read-notifications__text-link {
    font-weight: 700;
    color: #2929e0;
    transition: .3s;
}

.read-notifications__text-link:hover {
    opacity: .7;
}