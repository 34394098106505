.customer-view {
    color: #6B7A99;
}

.customer-view__modal-input-wrap {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.customer-view__modal-input {
    max-width: 500px;
    width: 100%;
}

.customer-view__header-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 30px 10px 30px 70px;
    border-bottom: 2px solid #F2F3F5;
}

.customer-view__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    font-weight: 700;
}

.customer-view__header-img {
    width: 40px;
    height: 40px;
}

.customer-view__header-title {
    white-space: nowrap;
}

.customer-view__header-btn-wrap {
    display: flex;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 30px 10px 30px 70px;
    border-bottom: 2px solid #F2F3F5;
}

.customer-view__header-btn {
    display: flex;
    align-items: center;
    gap: 13px;
    background: #4C6FFF;
    border-radius: 6px;
    padding: 12px 20px;
    color: #fff;
    margin-left: auto;
    font-size: 14px;
    transition: .3s;
}

.customer-view__header-btn:hover {
    background: #6B7A99;
}

.customer-view__header-btn-img {
    width: 18px;
    height: 18px;
}

.customer-view--wrap {
 
}

.customer-view__info {
    display: flex;
    gap: 50px;
    flex-wrap: wrap;
    padding-top: 35px;
}

.customer-view__info-img-wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 10px;
    border: 2px solid #DADEE6;
    width: 130px;
    height: 130px;
}

.customer-view__info-img-count {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #4C6FFF;
    border: 2px solid #4C6FFF;
    box-shadow: 0px 10px 30px rgba(51, 97, 255, 0.3);
    font-size: 12px;
    line-height: 20px;  
    color: #fff;
}

.customer-card__info-img-name {
    color: #4C6FFF;
    font-weight: 400;
    font-size: 36px;
    line-height: 20px;
    text-transform: uppercase;
    background: #E0E6FF;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.customer-view__info-text-wrap {
    padding: 20px 0;
    box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
    border-radius: 16px;
    max-width: 400px;
    width: 100%;
}

.customer-view__info-text-name {
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 5px;
    padding: 0 20px;
}

.customer-view__info-text-email {
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    color: #ADB8CC;
    margin-bottom: 25px;
    padding: 0 20px;
}

.customer-view__info-text-phone--wrap {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    padding: 0 20px;
    color: #4D5E80;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 20px;
}

.customer-view__info-text-phone-wrap {
    display: flex;
    align-items: center;
    gap: 15px;
}

.customer-view__info-text-phone-img {
    width: 18px;
    height: 18px;
}

.customer-view__info-text-costs-wrap {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 0 20px 20px;
    color: #4D5E80;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    border-bottom: 2px solid #F5F6F7;;
}

.customer-view__info-text-date--wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 0;
}

.customer-view__info-text-date-wrap {
    font-weight: 600;
    line-height: 20px;
}

.customer-view__info-text-date-title {
    color: #7D8FB3;
    font-size: 12px;
}

.customer-view__info-text-date {
    color: #4D5E80;
    font-size: 13px;
}

.customer-view__info-btn-ban-wrap {
    display: flex;
    align-items: center;
    gap: 11px;
    border: 2px solid #F5F6F7;
    border-radius: 5px;
    padding: 12px 15px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.customer-view__info-btn-ban-img {
    width: 19px;
    height: 18px;
}

.customer-view__purchases--wrap {
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.customer-view__purchases-wrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 2px solid #F2F3F5;
    margin-top: 15px;
}

.customer-view__purchases-text-wrap {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    width: 100%;
    border-top: 2px solid #F2F3F5;
    padding: 10px;
    cursor: pointer;
    transition: .3s;
}

.customer-view__purchases-text-wrap:hover {
    background: #ebeef2;
}

.customer-view__purchases-text {
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    min-width: 170px;
    max-width: 170px;
    width: 100%;
    overflow: hidden;
}

.customer-view__purchases-title {
    font-weight: 500;
}

.customer-view__purchases-title-email {
    color: #4C6FFF;
}