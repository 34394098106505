.header {
    background: var(--bgColorHeader);
}

.header__top-block-wrap {
    overflow: hidden;
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
    background: var(--bgColorTopBlockHeader);
  }
  
  .header__top-block {
    display: flex;
  }
  
  .header__top-block-line {
    color: var(--colorTopBlockHeader);
    flex-shrink: 0;
    margin: 0;
    padding: 7px 7px;
    min-width: 100%;
    white-space: nowrap;
    animation-name: marqueeLine;
    animation-duration: 6s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    text-align: center;
  }
  
  .header__top-block-line2 {
    color: var(--colorTopBlockHeader);
    flex-shrink: 0;
    margin: 0;
    padding: 7px 7px;
    min-width: 100%;
    white-space: nowrap;
    text-align: center;
  }
  
  @keyframes marqueeLine {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }

.header--wrap--1 {
    position: relative;
    padding-top: 23px;
    padding-bottom: 24px;
    display: flex;
    min-height: 85px;
    align-items: center;
    gap: 20px;
}

.header--wrap--2 {
    position: relative;
    padding-top: 23px;
    padding-bottom: 24px;
    display: flex;
    min-height: 85px;
    align-items: center;
    gap: 20px;
}

.header--wrap--3 {
    position: relative;
    padding-top: 23px;
    padding-bottom: 24px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
}

.header__mobile-menu-wrap {
    display: flex;
    align-items: center;
    gap: 20px;
}

.header__logo {
    position: absolute;
    top: 0px;
    left: 62px;
    width: 21vmin;
    height: 85px;
}

.header--wrap--3 .header__logo {
    left: 0;
}

.header--wrap--2 .header__logo {
    left: 15%;
}

.header__logo-img {
    object-fit: contain;
}

.header__no-logo {
    color: var(--colorHeaderText);
    position: relative;
    display: flex;
    line-height: 1.3;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
    white-space: nowrap;
    transition: .3s;
}

.header__no-logo:hover {
    scale: 1.15;
}

.header__no-logo::before {
    content: '';
    display: inline;
    width: 0%;
    height: 0.2em;
    position: absolute;
    bottom: -4px;
    background-color: var(--colorHeaderText);
    transition: width .3s ease-in;
}

.header__no-logo:hover::before {
    width: 100%;
}

.header__menu-wrap--1 {
    display: none;
    margin-left: 21vmin;
}

.header__menu-wrap--2 {
    display: none;
    margin-left: 21vmin;
}

.header__menu-wrap--3 {
    display: block;
    width: 100%;
    order: 3;
    margin-left: 21vmin;
}

.header__menu {
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.header__menu-wrap--3 .header__menu {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 35px; 
}

.header__menu-link {
    display: inline-block;
    color: var(--colorHeaderText);
    position: relative;
    display: flex;
    line-height: 1.3;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
    white-space: nowrap;
    transition: .3s;
}

.header__menu-link:hover {
    scale: 1.15;
}

.header__menu-link::before {
    content: '';
    display: inline;
    width: 0%;
    height: 0.2em;
    position: absolute;
    bottom: -4px;
    background-color: var(--colorHeaderText);
    transition: width .3s ease-in;
}
  
.header__menu-link-wrap:hover .header__menu-link::before,
.header__menu-link.active::before {
    width: 100%;
}
  
.header__menu-link-dropdown {
    width: 100%;
    position: absolute;
    display: none;
    left: 0;
    top: 16px;
    z-index: 12;
    background: transparent;
}
  
.header__menu-link-dropdown-wrap {
    display: inline-block;
    margin-top: 36px;
    padding: 28px 28px 28px 0;
    column-count: 4;
    column-gap: 10px;
    background: var(--bgSubCategories);
    padding-left: 20px;
}

.header__menu-wrap--3 .header__menu-link-dropdown-wrap {
    width: 100%;
}

.header__menu-sublink-dropdown {
    position: relative;
    width: fit-content;
    display: flex;
    line-height: 1.3;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    color: var(--colorSubCategoriesText);
    white-space: nowrap;
    margin-bottom: 10px;
    padding-bottom: 2px;
    transition: .3s;
}

.header__menu-sublink-dropdown:hover {
    scale: 1.15;
}

.header__menu-sublink-dropdown::before {
    content: '';
    width: 0%;
    height: 0.2em;
    position: absolute;
    left: 0;
    bottom: 0px;
    background-color: var(--colorSubCategoriesText);
    transition: width .3s ease-in;
}

.header__menu-sublink-dropdown:hover::before {
    width: 100%;
}

.header__menu-link-wrap:hover .header__menu-link-dropdown {
    display: block;
}
  
.header__wrap-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-left: auto;
}

.header--wrap--3 .header__wrap-btn {
    justify-content: end;
}

.header__search-btn-wrap {
    display: none;
}

.header--wrap--3 .header__search-btn-wrap {
    display: inline-block;
    margin-left: 21vmin;
}

.header__language-selection-wrap {
    position: relative;
    cursor: pointer;
    transition: 0.3s;
    color: var(--colorHeaderText);
}

.header__language-selection {
    min-width: 33px;
    transition: 0.3s;
}

.header__language-selection-drop {
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: flex-end;
    color: var(--colorHeaderText);
    background: var(--bgColorHeader);
    opacity: 0;
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 2px;
    padding: 3px 6px;
    z-index: -5;
    transition: 0.3s;
}

.header__language-selection-wrap:hover .header__language-selection-drop {
    opacity: 1;
    z-index: 5;
}

.header__language-selection-drop-item {

}

@media (min-width: 380px) {
    .header__logo {
        display: inline-block;
    }
}

@media (min-width: 423px) {
    .header--wrap--3 .header__wrap-btn {
        margin-left: auto;
    }
}

@media (min-width: 430px) {
    .header--wrap--3  .header__search-btn-wrap {
        display: inline-block;
     }
}

@media (min-width: 524px) {
    .header--wrap--3 .header__search-btn-wrap {
        order: 0;
    }
}

@media (min-width: 576px) {
    .header--wrap {
        gap: 20px;
    }
    .header--wrap--2 .header__search-btn-wrap {
        display: inline-block;
    }
}
  
@media (min-width: 658px) {
    .header--wrap--1 .header__search-btn-wrap {
        display: inline-block;
    }
}
@media (min-width: 1187px) {
    .header__menu-wrap--1 {
        display: block;
    }
    .header__logo {
        left: 0;
    }
}