.message-client {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.message-client__message-wrap {
    display: flex;
    flex-direction: column-reverse;
    gap: 25px;
    height: 100%;
    padding-bottom: 30px;
}

.message-client__message1 {
    margin-left: auto;
    font-size: 14px;
}

.message-client__message1-client {
    text-align: end;
    margin-bottom: 7px;
    margin-right: 60px;
}

.message-client__message1-text-wrap {
    display: flex;
    gap: 8px;
    align-items: end;
}

.message-client__message1-text--wrap {
    position: relative;
    min-width: 312px;
    max-width: 410px;
    background: #4C6FFF;
    padding: 16px 16px 5px;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    color: #fff;
}

.message-client__message1-text-arrow {
    position: absolute;
    right: -2.8px;
    bottom: -13px;
    transform: rotateZ(166deg);
    width: 10px;
    height: 15px;
}

.message-client__message1-text {

}

.message-client__message1-text-date {
    text-align: end;
    margin-top: 9px;
}

.message-client__message1-text-img {
    width: 50px;
    height: 50px;
    transform: translateY(10px);
}
/* ============================== */

.message-client__message2 {
    margin-right: auto;
    font-size: 14px;
}

.message-client__message2-client {
    margin-bottom: 7px;
    margin-left: 60px;
}

.message-client__message2-text-wrap {
    display: flex;
    flex-direction: row-reverse;
    gap: 8px;
    align-items: end;
}

.message-client__message2-text--wrap {
    position: relative;
    min-width: 312px;
    max-width: 410px;
    background: #F3F5F7;
    padding: 16px 16px 5px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    color: #16192C;
}

.message-client__message2-text-arrow {
    color: #F3F5F7;
    position: absolute;
    left: -3.6px;
    bottom: -13px;
    transform: rotateZ(197deg);
    width: 10px;
    height: 15px;
}

.message-client__message2-text {

}

.message-client__message2-text-date {
    text-align: end;
    margin-top: 9px;
}

.message-client__message2-text-img {
    width: 50px;
    height: 50px;
    object-fit: contain;
    transform: translateY(10px);
}

/* ========================== */
.message-client__write {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 25px;
    border: 1px solid rgba(46, 91, 255, 0.08);
    border-radius: 1px;
}

.message-client__write-emoji-wrap {
    position: absolute;
    bottom: 100%;
    left: 0;
    z-index: 10;
}

.message-client__write-input-wrap {
    position: relative;
    width: 100%;
}

.message-client__write-emoji-img {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    z-index: 10;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.message-client__write-pictures-wrap {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    z-index: 10;
    cursor: pointer;
}

.message-client__write-pictures-input {
    width: 0;
    height: 0;
    opacity: 0;
}

.message-client__write-pictures-lable {

}

.message-client__write-pictures-img {
    width: 23px;
    height: 22px;
}

.message-client__write-input {
    width: 100%;
    border-radius: 6px;
}

.message-client__write-input .MuiInputBase-root {
    background: #F5F6F7;
    padding: 0 14px;
}
.message-client__write-input .MuiInputLabel-outlined {
    padding-left: 55px;
    padding-right: 40px;
}
.message-client__write-input .css-14lo706 {
    margin-left: 40px;
}
.message-client__write-input .MuiInputBase-input {
    padding: 16.5px 55px;
}

.message-client__write-btn-send {
}

.message-client__write-btn-send-img {
    width: 63px;
    height: 63px;
}

.message-client__write-result-img-wrap {
    position: absolute;
    bottom: 100%;
    right: 70px;
    display: flex;
    gap: 2px;
    z-index: 10;
}

.message-client__write-result-img {
    width: 100px;
    /* aspect-ratio: 9/12; */
}

.message-client__write-result-img-close {
    width: 30px;
    height: 30px;
    cursor: pointer;
    background: #fff;
    border-radius: 50%;
}