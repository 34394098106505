.drop-down-menu {
    position: absolute;
    top: -140%;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background: #ffffff;
    transition: .8s;
}

.drop-down-menu-active--1 {
    position: absolute;
    top: 82px;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background: #ffffff;
    transition: .8s;
}

.drop-down-menu-active--2 {
    position: absolute;
    top: 82px;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background: #ffffff;
    transition: .8s;
}

.drop-down-menu-is-top-block {
    top: 119px;
}

.drop-down-menu__header {
    padding-top: 20px;
    padding-bottom: 20px;
    background: var(--bgColorHeader)
}

.drop-down-menu__header--wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    align-items: center;
    color: #ffffff;
}

.drop-down-menu__header {
    
}

.drop-down-menu__header-link {
    display: inline-block;
    position: relative;
    display: flex;
    line-height: 1.3;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
    white-space: nowrap;
    transition: .3s;
    margin-right: 35px;
    color: var(--colorHeaderText);
}

.drop-down-menu__header-link:hover {
    scale: 1.15;
}

.drop-down-menu__header-link::before {
    content: '';
    display: inline;
    width: 0%;
    height: 0.2em;
    position: absolute;
    bottom: -4px;
    background-color: var(--colorHeaderText);
    transition: width .3s ease-in;
}
  
.drop-down-menu__header-link:hover::before,
.drop-down-menu__header-link.active::before {
    width: 100%;
}

.drop-down-menu__sub-categories--wrap {

}

.drop-down-menu__sub-categories-link-wrap {
    border-bottom: 1px solid #E4E4E5;
}
.drop-down-menu__sub-categories-link {
    display: inline-block;
    font-size: 14px;
    text-transform: uppercase;
    padding-top: 14px;
    padding-bottom: 14px;
}

.drop-down-menu__btn-wrap {
    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #E4E4E5;
}

.drop-down-menu__btn-img {
    width: 23px;
    height: 23px;
    display: inline-block;
    margin-right: 7px;
}

.drop-down-menu__btn-text {
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
}

.drop-down-menu__contact {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 14px;
    padding-bottom: 14px;
}

.drop-down-menu__contact-tel-wrap {
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: var(--colorHeaderText);
}

.drop-down-menu__contact-tel {
    display: inline-block;
    margin-left: 5px;
    font-size: 18px;
    line-height: 24px;
}

.drop-down-menu__contact-social-wrap {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.drop-down-menu__contact-social {
    width: 25px;
    height: 25px;
}

.drop-down-menu__logo {
    width: 21vmin;
    height: 85px;
    object-fit: contain;
}

@media (min-width: 1080px) {
    .drop-down-menu-active--1 {
        display: none;
    } 
}