.mobile-menu--1 {
    display: inline-block;
    cursor: pointer;
}

.mobile-menu--2 {
    display: inline-block;
    cursor: pointer;
}

.mobile-menu--3 {
    display: none;
}
  
  .bar1, .bar2, .bar3 {
    width: 26px;
    height: 4px;
    background-color: var(--colorHeaderText);
    margin: 6px 0;
    transition: 0.4s;
  }
  
  .change .bar1 {
    transform: translate(0, 11px) rotate(-45deg);
  }
  
  .change .bar2 {opacity: 0;}
  
  .change .bar3 {
    transform: translate(0, -11px) rotate(45deg);
  }
 
  @media (min-width: 1187px) {
    .mobile-menu--1 {
      display: none;
    } 
  }