.select-status {

}

.select-status__setting-title {
    font-size: 18px;
    font-weight: 600;
    padding: 3px 13px;
}

.select-status__setting-btn {
    font-size: 22px !important;
    font-weight: 700 !important;
}

.select-status .MuiButtonBase-root:hover {
    background-color: rgb(9 119 228 / 11%);
}