.input-color--wrap {
    position: relative;
    display: inline-block;
    margin-bottom: 10px;
    margin-top: 10px;
}
.input-color--wrap .sketch-picker {
    width: 176px !important;
}

.input-color__button {
    display: flex;
    gap: 7px;
    align-items: center;
    min-width: 150px;
    height: 45px;
    padding: 5px 10px 5px 5px;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 6px;
    overflow: hidden;
}

.input-color__button-color {
    width: 32px;
    min-width: 32px;
    height: 32px;
    border-radius: 6px;
    border: 1px solid;
}

.input-color__button-color-text {
    white-space: nowrap;
}

.input-color__label {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 296px;
    gap: 5px;
    z-index: 20;
    background: #fff;
    width: 294px;
    border: 1px solid;
}