.blogs-view-wrap {

}

.blogs-view {
    min-height: calc(100vh - 260px);
    margin-top: 100px;
    margin-bottom: 40px;
    padding-top: 20px;
}

.blogs-view__title {
    text-align: center;
    font-size: 28px;
    margin-bottom: 60px;
}

.blogs-view__cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    cursor: pointer;
}

.blogs-view__card {
    width: 100%;
    box-shadow: 2px 1px 11px rgb(0 0 0 / 52%);
    transition: .3s;
}

.blogs-view__card:hover {
    box-shadow: 5px 5px 30px rgb(0 0 0 / 55%);
    scale: 1.02;
}

.blogs-view__card-img {
    width: 100%;
    height: 200px;
}

.blogs-view__card-title {
    font-size: 30px;
    margin: 20px;
}

.blogs-view__card-text {
    color: rgb(131, 144, 170);
    font-size: 15px;
    margin: 20px;
}

@media (min-width: 576px) {
    .blogs-view__card {
        width: 80%;
    }
}

@media (min-width: 768px) {
    .blogs-view__card {
        width: 47%;
    }
}