.layout-user {
    position: relative;
}

.layout-user__search-barcode-input {
    width: 100%;
    margin-bottom: 15px !important;
}

.layout-user__search-barcode-input .MuiInputBase-input  {
    padding: 11.5px 14px;
}

.layout-user__search-barcode-btn {
    display: inline-block;
    background: #4C6FFF;
    color: #fff;
    border-radius: 6px;
    padding: 12px 20px; 
    transition: .3s;
}

.layout-user__search-barcode-btn:hover {
    background: #6B7A99;
}

.layout-user--wrpa {
    /* overflow: hidden; */
}

.layout-user__sidenav {
    width: 100%;
    /* position: fixed; */
    position: static;
    /* z-index: 10; */
    top: 0;
    left: 0;
    height: 100%;
    /* background-color: #0d48ac; */
    /* overflow-x: hidden; */
    padding: 20px 10px 20px 0;
    /* padding: 20px 15px; */
    flex: 0 0 300px;
    top: 88px;
    background: #fff;
    transition: .3s;
}

.layout-user__sidenav--open {
    flex: 0 0 300px;
    position: absolute;
    top: 88px;
    left: 0;
    padding: 20px 15px;
    z-index: 12;
    transition: 0.3s;
}

.layout-user__sidenav--close {
    position: static;
    flex: 0 0 61px;
    transition: .3s;
}

/* .layout-user__sidenav-btn-wrap {
    display: flex;
    justify-content: end;
    margin-bottom: 15px;
    margin-right: 30px;
    color: #ffffff;
    font-size: 33px;
    transition: .3s;
} */

/* .layout-user__sidenav-btn {
    transition: .3s;
    cursor: pointer;
}

.layout-user__sidenav-btn:hover {
    scale: 1.14;
} */

.layout-user__sidenav-link-shop {
    position: relative;
    background: linear-gradient(92.71deg, #33CEFF 3.48%, #6884E6 45.5%, #C054FF 95.45%);
    box-shadow: 0px 2px 5px rgb(0 0 0 / 25%);
    border-radius: 4px;
    color: #fff;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    max-width: 268px;
    padding: 8px 13px 8px 19px;
    /* margin: 25px 0; */
    margin-bottom: 25px;
    transition: .3s;
    height: 62px;
}

.layout-user__sidenav-link-shop:hover {
    scale: 1.05;
}

.layout-user__sidenav-link-shop-house {
    width: 12px;
    height: 12px;
}

.layout-user__sidenav-link-shop-text-wrap {
    transition: .3s;
}

.layout-user__sidenav-link-shop-text-wrap {
    position: absolute;
    left: 69px;
    top: 50%;
    transform: translateY(-50%);
    transition: .3s;
}

.layout-user__sidenav-link-shop-text-wrap--close {
    z-index: -100;
    pointer-events: none;
    opacity: 0;
}

.layout-user__sidenav-link-shop-text-wrap--open {
    pointer-events: all;
    /* z-index: 10; */
    opacity: 1;
}

.layout-user__sidenav-link-shop-title {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 6px;
    white-space: nowrap;
}

.layout-user__sidenav-link-shop-text {
    font-weight: 400;
    font-size: 14px;
    white-space: nowrap;
}

.layout-user__sidenav-link-shop-arow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 239px;
    width: 12px;
    height: 18px;
    transition: .3s;
}

.layout-user__sidenav-link-shop-arow--close {
    pointer-events: none;
    z-index: -100;
    opacity: 0;
}

.layout-user__sidenav-link-shop-arow--open {
    pointer-events: all;
    /* z-index: 10; */
    opacity: 1;
}

.layout-user__sidenav-link {
    position: relative;
    padding: 10px 0px 10px 14px;
    /* padding: 10px; */
    text-decoration: none;
    font-size: 16px;
    color: #545D69;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 7px;
    margin-top: 5px;
    margin-bottom: 20px;
    border-radius: 45px;
    transition: 0.15s;
}

.layout-user__sidenav-link:hover {
    background: #EBEEF2;
    color: #5F41B2;
    scale: 1.05;
}

.layout-user__sidenav-link.active {
    background: #EBEEF2;
    color: #5F41B2;
    scale: 1.05;
}

.layout-user__sidenav-link-icon-hover {
    width: 24px;
    height: 24px;
    display: none;
}

.layout-user__sidenav-link.active .layout-user__sidenav-link-icon,
.layout-user__sidenav-link:hover .layout-user__sidenav-link-icon {
    display: none;
}

.layout-user__sidenav-link.active .layout-user__sidenav-link-icon-hover,
.layout-user__sidenav-link:hover .layout-user__sidenav-link-icon-hover {
    display: block;
}

.layout-user__sidenav .active {
    background: #EBEEF2;
}

.layout-user__sidenav-link-message-wrap {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 0;
    opacity: 0;
    position: absolute;
    left: 69px;
    top: 50%;
    transform: translateY(-50%);
}

.layout-user__sidenav-link-text {
    position: absolute;
    left: 69px;
    top: 50%;
    transform: translateY(-50%);
    transition: .3s;
}

.layout-user__sidenav-link-text--close {
    z-index: -100;
    opacity: 0;
    pointer-events:none;
}

.layout-user__sidenav-link-text--open {
    opacity: 1;
    z-index: 10;
    pointer-events: all;
    transition: .5s;
}

.layout-user__sidenav-link-icon {
    width: 24px;
    height: 24px;
}

.layout-user__sidenav-link-message-circle {
    width: 21px;
    height: 20px;
    border-radius: 50%;
    background-color: #5F41B2;
    font-size: 11px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 38px;
    padding-bottom: 1px;
}

.layout-user__closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}

.layout-user__main {
    display: flex;
    transition: 0.3s;
}

.layout-user__header-wrap {
    border-bottom: 1px solid rgba(180, 182, 197, 0.38);
}

.layout-user__header {
    display: flex;
    gap: 15px;
    justify-content: space-between;
    align-items: center;
    min-height: 85px;
    /* box-shadow: 5px 5px 30px rgb(0 0 0 / 15%); */
    padding: 15px 10px;
}

.layout-user__header-logo {
    width: 37px;
    height: 55px;
}

.layout-user__header-search-wrap {
    position: relative;
    flex: 1 1;
    display: flex;
    justify-content: end;
}

.layout-user__header-search-tel-wrap {
    position: absolute;
    top: 10px;
    z-index: 10;
    background: #fff;
    padding: 3px;
}

.layout-user__header-search-btn-close {
    width: 30px;
    height: 30px;
    margin-left: 5px;
}

.layout-user__header-search-btn-close-img {

}

.layout-user__header-search--wrap {
    position: relative;
    width: 100%;
}

.layout-user__header-search {
    width: 100%;
}

.layout-user__header-search .MuiInputBase-input,
.layout-user__header-search .MuiFormLabel-root {
    padding-left: 50px;
}
.layout-user__header-search .css-14lo706 {
    margin-left: 36px;
}

.layout-user__header-search-img {
    position: absolute;
    top: 16px;
    left: 18px;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.layout-user__header-menu {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    width: 25px;
    height: 25px;
    cursor: pointer;
    margin-left: auto;
    margin-bottom: 25px;
}

.layout-user__header-menu-span {
    width: 100%;
    height: 2px;
    background-color: #000000;
}

.layout-user__header-menu-span2 {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.layout-user__header-menu-span--open1 {
    width: 68%;
}

.layout-user__header-menu-span--open3 {
   width: 48%; 
}

.layout-user__header-btn--wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.layout-user__header-btn {
    display: flex;
    gap: 20px;
    align-items: center;
}

.layout-user__header-btn-linck {
    display: flex;
    align-items: center;
    gap: 5px;
}

.layout-user__header-btn-img-wrap {
    position: relative;
}

.layout-user__header-btn-img-barcode {
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.layout-user__header-btn-img {
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.layout-user__header-btn-circle {
    position: absolute;
    top: -15px;
    right: -9px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #5F41B2;
    font-size: 11px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.layout-user__header-btn-img-text {
    display: none;
    color: #16192C;
    font-size: 14px;
}

.layout-user__header-avatar-wrap {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-left: 8px;
}

.layout-user__header-logo-wrap {
    /* padding-left: 21vmin; */
}

.layout-user__header-avatar-img {
    display: inline-block;
    width: 52px;
    height: 52px;
    border-radius: 50%;
}

/* ------------------- */

.layout-user__select--wrap {
    position: relative;
}
.layout-user__select-wrap {
    display: inline-block;
    cursor: pointer;
}

.layout-user__select {
    display: flex;
} 

.layout-user__select-btn-wrap {
    position: relative;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;
    margin-left: 5px;
    transition: .3s;
}

.layout-user__select-btn-wrap:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

.layout-user__select-btn {
    position: absolute;
    top: 4px;
    left: 7px;
    width: 12px;
    height: 12px;
    border-left: 2px solid #000000;
    border-bottom: 2px solid #000000;
    transform: rotate(-45deg);
}

.layout-user__select-btn--active {
    top: 9px;
    transform: rotate(135deg);
}

.layout-user__option-wrap {
    position: absolute;
    background: #ffffff;
    /* height: 350px; */
    /* width: 141px; */
    overflow: auto;
    display: flex;
    gap: 5px;
    flex-direction: column;
    right: 0;
    top: 69px;
    padding-left: 10px;
    padding-right: 10px;
    opacity: 0;
    text-align: end;
    z-index: -1;
    color: #545D69;
    transition: .3s;
}

.layout-user__option-wrap-close {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: -1;
}

.layout-user__option-wrap--active {
    opacity: 1;
    z-index: 11;
    color: #545D69;
}

.layout-user__option-name {
    margin-top: 3px;
    font-weight: 700;
    margin-bottom: 3px;
    padding: 5px 10px;
}

.layout-user__option-exit {
    display: flex;
    gap: 15px;
    justify-content: end;
    align-items: center;
}

.layout-user__option-img-exit {
    width: 25px;
    height: 25px;
}

.layout-user__option {
    cursor: pointer;
    transition: .3s;
    margin-top: 3px;
    font-weight: 700;
    margin-bottom: 3px;
    padding: 5px 10px;
}

.layout-user__option-checkbox-wrap {
    display: flex;
    justify-content: end;
    gap: 5px;
}

.layout-user__option:hover {
    background: rgba(0, 0, 0, 0.2);
}
/* ------------------- */

.layout-user__outlet-wrap {
    /* display: flex; */
    min-height: calc(100vh - 87px);
    flex: 1 1;
    /* border-left: 1px solid #E5E5E5; */
    border-left: 1px solid rgba(180, 182, 197, 0.38);
    width: calc(100vw - 81px);
    font-size: 12px;
}


@media (min-width: 507px) {
  /* .layout-user__header-btn {
      display: block;
  } */
}
  
@media (min-width: 576px) {
    .layout-user__header-btn-text {
        display: inline-block;
    }
    .layout-user__header {
        padding: 15px 18px;
    }
    .layout-user__search-barcode-input .MuiInputBase-input  {
        padding: 16.5px 14px;
    }
    .layout-user__outlet-wrap {
        font-size: 16px;
    }
}
  
@media (min-width: 768px) {
    .layout-user__header {
        gap: 35px;
        padding: 15px 43px;
    }

    .layout-user__sidenav--open {
        position: static;
        padding: 20px 15px 20px 0;

    }

    .layout-user__outlet-wrap {
        width: 100%;
    }


/*     
    .layout-user__sidenav--close {
        left: -500px;
    } */
}

@media (min-width: 840px) {
    .layout-user__header-btn-img-text {
        display: block;
    }
}

@media (min-width: 960px) {
    .layout-user__header-menu {
        display: none;
    }

    .layout-user__header-search--wrap {
        width: 79.5%;
    }

}
  
@media (min-width: 1080px) {
    
}