.footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto auto 0;
}

.footer__modal-input-label {
    position: relative;
    display: block;
    font-size: 16px;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 10px;
}

.footer__modal-input-label-tel {

}

.footer__modal-input-label-tel::after {
    content: '*';
    position: absolute;
    top: 0;
    right: 147px;
    color: #E31E24;
}

.footer__modal-input {
    padding: 10px 20px;
    max-width: 45%;
    width: 100%;
    min-width: 200px;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 17px;
    color: #333;
    outline: none;
    border-radius: 8px;
    background: #f6f7fa;
    border: 1px solid #065bea;
}

.footer__modal-textarea {
    border: 1px solid #ada5a5;
}

.footer__top--wrap {
    width: 100%;
    background: #f9f9f9;
}

.footer__top {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    flex-direction: column;
    align-items: center;
    background: var(--bgColorMain);
    color: var(--colorMainText);
    font-size: 12px;
}

.footer__top-title {
    font-size: 14px;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    color: var(--colorMainText);
}

.footer__top-sub-title {
    font-size: 18px;
    font-weight: 700;
    color: var(--colorMainText);
}

.footer__top-mail-wrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.footer__top-mail {
    color: var(--colorMainText);
    min-width: 200px;
    border-bottom: 1px solid var(--colorMainText);
    padding-bottom: 4px;
}

input.footer__top-mail::placeholder {
    color: var(--colorMainText);
}

/* ------------------------------------------------ */

.footer__bottom {
    padding-top: 10px;
}

.footer__bottom--wrap {
    width: 100%;
    background: var(--bgColorFooter);
}

.footer__bottom-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
    margin-bottom: 10px;
}

.footer__bottom-social {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 28px;
}

.footer__bottom-social-logo {
}

.footer__bottom-social-logo-img {
    width: 21vmin;
    height: 85px;
    object-fit: contain;
}

.footer__bottom-social-link-wrap {
    display: flex;
    gap: 17px;
}

.footer__bottom-social-link {
    width: 30px;
    cursor: pointer;
}

.footer__bottom-catalog {
    display: none;
}

.footer__bottom-catalog-title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 16px;
    text-transform: uppercase;
    color: var(--colorFooterText);
}

.footer__bottom-catalog-link-wrap {

}

.footer__bottom-catalog-link-wrap li {
    font-size: 16px;
    margin-bottom: 16px;
}

.footer__bottom-catalog-link {
    color: var(--colorFooterText);
}
/* ----------------------- */
.footer__bottom-contact {
    text-align: center;
}

.footer__bottom-contact-title {
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    margin-bottom: 15px;
    font-weight: 700;
    color: var(--colorFooterText);
}

.footer__bottom-contact-adres {
    margin-bottom: 15px;
    color: var(--colorFooterText);
}

.footer__bottom-contact-tel {
    margin-bottom: 15px;
    display: block;
    color: var(--colorFooterText);
}

.footer__bottom-license {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    padding: 15px 0;
}

.footer__bottom-license-text {
    text-align: center;
    font-size: 12px;
    color: var(--colorFooterText);
}

.footer__bottom-license-link {
    color: #065bea;
}
@media (min-width: 450px) {
    .footer__top-mail-wrap {
        display: flex;
        flex-direction: row;
        gap: 0;
    }
    .footer__bottom-info {
        flex-direction: row;
    }
}
@media (min-width: 576px) {
    .footer__top {
        padding-top: 30px;
        padding-bottom: 20px;
        font-size: 16px;
    }
    .footer__top-title {
        font-size: 26px;
    }
    .footer__bottom {
        padding-top: 32px;
    }
}

@media (min-width: 700px) {
    .footer__bottom-catalog {
        display: inline-block;
    }
}
  
@media (min-width: 768px) {
    .footer__bottom-license {
        flex-direction: row;
    }
}