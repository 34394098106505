

.product-card-user__card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 270px;
    padding-bottom: 5px;
    box-shadow: 0px 0px 1px 0px rgba(12, 26, 75, 0.24);
    border-radius: 16px;
    background: #fff;
    color: #333;
    font-size: 12px;
}

.product-card-user__card-wrap {
    position: relative;
}

.product-card-user__card-swiper-wrap {
  
}

.product-card-user__card-swiper {
    padding-left: 40px !important;
    padding-right: 40px !important;
}

.product-card-user__card-swiper-wrap .swiper-slide {
    aspect-ratio: 9/12;
}

.product-card-user__card-swiper-wrap .swiper-button-next {
    top: 78% !important;
    right: 5px !important;
    width: 35px;
    height: 35px;
    transform: rotate(180deg);
}

.product-card-user__card-swiper-wrap .swiper-button-prev {
    top: 78% !important;
    left: 5px !important;
    width: 35px;
    height: 35px;
}

.product-card-user__card-swiper-wrap .swiper-button-next::after {
    content: '' !important;
    background-image: url(../../assets/images/arrowCircle.svg);
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    transition: .3s;
} 

.product-card-user__card-swiper-wrap .swiper-button-prev::after {
    content: '' !important;
    background-image: url(../../assets/images/arrowCircle.svg);
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    transition: .3s;
} 

.product-card-user__card-swiper-wrap .swiper-button-next:hover::after {
    transform: translateX(-10px);
}

.product-card-user__card-swiper-wrap .swiper-button-prev:hover::after {
    transform: translateX(-10px);
}

.product-card-user__card-swiper-img {
    object-fit: contain;
}

.product-card-user__card-swiper-img-none {
    height: 100%;
    aspect-ratio: 8/11;
    padding: 15px;
    object-fit: contain;
}

.product-card-user__card-info {
    display: flex;
    flex-direction: column;
    padding: 5px 25px;
}

.product-card-user__card-info-name-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.product-card-user__card-info-name {
    /* font-weight: 700;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    //styleName: Body 2; */
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: left;
}

.product-card-user__card-info-count-wrap {
    display: flex;
    align-items: center;
    gap: 5px;
    text-align: end;
    color: #9D9D9D;
}

.product-card-user__card-info-count-img {
    display: inline-block;
    width: 18px;
    height: 18px;
}

.product-card-user__card-info-count {
}

.product-card-user__card-info-details {
    height: 22px;
    color: #425466;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.product-card-user__card-info-price-wrap {
    display: flex;
    align-items: baseline;
    gap: 10px;
    margin-bottom: 15px;
}

.product-card-user__card-info-new-price {
    font-size: 22px;
}

.product-card-user__card-info-price {
    font-size: 22px;
    margin-left: -7px;
}

.product-card-user__card-info-price-old {
    font-size: 14px;
    color: #425466;
}

.product-card-user__card-btn-see-wrap {
    display: inline-block;
    margin-bottom: 20px;
}

.product-card-user__card-btn-see {
    display: inline-block;
    background: #4C6FFF;
    color: #fff;
    border-radius: 6px;
    padding: 12px 20px; 
    transition: .3s;
}

.product-card-user__card-btn-see:hover {
    background: #6B7A99;
}

.product-card-user__card-info-category-wrap {
    display: flex;
    align-items: baseline;
    gap: 10px;
    margin-bottom: 5px;
}

.product-card-user__card-info-category-title {
    color: #171717ad;
}

.product-card-user__card-info-category {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    transition: .3s;
    margin-left: -5px;
}

.product-card-user__card-info-color-wrap {
    display: flex;
    align-items: baseline;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 10px;
    /* min-height: 74px; */
}

.product-card-user__card-info-color-title {
    color: #171717ad;
}

.product-card-user__card-info-size-wrap {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 5px;
}

.product-card-user__card-info-size-title {
    color: #171717ad;
}

.product-card-user__card-info-size {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #F3F5F7;
    border-radius: 6px;
    padding: 4px;
    min-width: 30px;
}

.MuiTooltip-tooltipPlacementLeft, .MuiTooltip-tooltipPlacementBottom {
    background: inherit !important;
}

/* ----------------- */
.product-card-user__card-info-text {
    max-height: 42px;
    overflow: auto;
}

.product-card-user__card-btn-wrap {
    transform: .3s;
}

.product-card-user__card-btn {
    position: absolute;
    width: 28px;
    height: 28px;
    z-index: 2;
    cursor: pointer;
    transform: .3s;
}

.product-card-user__card-btn-delete {
    left: 5px;
    top: 100px;
}
.product-card-user__card-btn-delete--hover {
    display: none;
    left: 5px;
    top: 100px;
}

.product-card-user__card-btn-edite {
    left: 5px;
    top: 60px;
}
.product-card-user__card-btn-edite--hover {
    display: none;
    left: 5px;
    top: 60px;
}

.product-card-user__card-btn-hide {
    left: 5px;
    top: 20px;
}
.product-card-user__card-btn-hide--hover {
    display: none;
    left: 5px;
    top: 20px;
    /* padding: 2px; */
}
.product-card-user__card-btn-wrap:hover .product-card-user__card-btn-delete--hover,
.product-card-user__card-btn-wrap:hover .product-card-user__card-btn-edite--hover,
.product-card-user__card-btn-wrap:hover .product-card-user__card-btn-hide--hover {
    display: block;
}
.product-card-user__card-btn-wrap:hover .product-card-user__card-btn-delete,
.product-card-user__card-btn-wrap:hover .product-card-user__card-btn-edite,
.product-card-user__card-btn-wrap:hover .product-card-user__card-btn-hide {
    display: none;
}


.product-card-user__card-btn-plus {
    position: absolute;
    top: 20px;
    right: 10px;
}

.product-card-user__card-btn-minus {
    position: absolute;
    top: 60px;
    right: 10px;
}

.product-card-user__card-btn:disabled {
    opacity: .5;
}

@media (min-width: 576px) {
    .product-card-user__card {
        font-size: 14px;
        max-width: 350px;
    }
    .product-card-user__card-btn-delete--hover,
    .product-card-user__card-btn-edite--hover,
    .product-card-user__card-btn-hide--hover,
    .product-card-user__card-btn-hide,
    .product-card-user__card-btn-edite,
    .product-card-user__card-btn-delete {
        left: 10px;
    }
    .product-card-user__card-swiper {
        padding-left: 45px !important;
        padding-right: 45px !important;
    }
}

@media (min-width: 800px) {
    .product-card-user__card {
        min-height: 700px;
    }
}