.login-btn {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-btn__img {
    width: 20px;
    height: 20px;
}

.login-btn__text {
    font-size: 14px;
    color: var(--colorHeaderText);
}

.login-btn__login-modal-input-wrap {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.login-btn__login-modal-input {
}

.login-btn__login-modal-error {
    color: red;
    font-size: 14px;
    margin-top: 15px;
}