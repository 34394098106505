.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: var(--bgColorMain);
    color: var(--colorMainText);
    font-size: 14px;
}
@media (min-width: 576px) {
    .layout {
        font-size: 16px;
    }
  
}
  
@media (min-width: 768px) {
 
}
  
@media (min-width: 1080px) {

}