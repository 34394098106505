.animated-text__hidden {
    opacity:0;
}

.animated-text {
    font-size: 25px;
    font-weight: 500;
    text-align:center;
    display:block;
    color:#ffffff;
    margin: 0 auto;
    white-space: nowrap;
}

.animated-text__underscore {
    display:inline-block;
    position:relative;
    top:-0.14em;
    left:10px;
    white-space: nowrap;
}

@media (min-width: 576px) {
    .animated-text {
        font-size: 40px;
    }
}

@media (min-width: 768px) {
    .animated-text {
        font-size: 60px;
    }
}