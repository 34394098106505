.user-product {
    min-height: calc(100vh - 140px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1;
    font-size: 12px;
    padding-bottom: 55px;
}

.user-product--wrap {
    position: relative;
}

.user-product__sub-title-wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-left: 15px;
    gap: 10px;
    font-weight: 700;
}

.user-product__sub-title-products-img {
    width: 28px;
    height: 28px;
    margin-right: 20px;
}

.user-product__sub-title {
    color: #6B7A99;
    display: flex;
    align-items: center;
}

.user-product__tab-wrap {
    flex: 1 1;
    display: flex;
    justify-content: center;
}
.user-product__tab-wrap .MuiButtonBase-root {
    font-size: 12px;
    padding: 10px;
}

.user-product__sub-title-btn {
    display: flex;
    align-items: center;
    gap: 5px;
    background: #4C6FFF;
    color: #fff;
    border-radius: 6px;
    padding: 5px 10px;
    transition: .3s;
    margin-left: auto;
}

.user-product__sub-title-btn:hover:not([disabled]) {
    background: #6B7A99;
}

.user-product__sub-title-btn:disabled {
    opacity: .5;
}

.user-product__sub-title-btn-img {
    width: 28px;
    height: 28px;
}

.user-product__filter--wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    border-top: 2px solid #F2F3F5;
    border-bottom: 2px solid #F2F3F5;
}

.user-product__filter-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    flex: 1 1;
    padding-top: 20px;
    padding-left: 10px;
    padding-bottom: 20px;
}

.user-product__filter {
    display: flex;
    align-items: center;
}

.user-product__filter-input {
    
}
.user-product__filter-input .MuiInputBase-root {
    border-radius: 0;
}
.user-product__filter-input .MuiInputBase-input  {
    padding: 9px 8px;
}

.user-product__filter-btn {
    border: 2px solid black;
    background-color: white;
    padding: 4px 28px;
    font-size: 14px;
    cursor: pointer;
    border-color: rgba(0, 0, 0, 0.23);
    color: #6B7A99;
    height: 41px;
    display: block;
    transition: .3s;
}

.user-product__filter-btn:hover {
    background: #6B7A99;
    color: white;
}

/* -------------- */
.user-product__price-select {
    max-width: 280px;
}

.user-product__price-option-checkbox-wrap {
    display: flex;
    justify-content: end;
    gap: 5px;
    padding: 5px;
}

.user-product__price-option:hover {
    background: rgba(0, 0, 0, 0.2);
}
/* ----------- */
.user-product__category-select {
    max-width: 280px;
}

.user-product__category-selected {
    font-weight: 700;
}

.user-product__look-btn-wrap {
    display: flex;
    margin-bottom: 15px;
    margin-left: auto;
}

.user-product__look-btn {
    width: 45px;
    height: 42px;
    padding: 13px;
    border: 2px solid #F2F3F5;
    transition: .3s;
}

.user-product__look-btn-left {
    border-radius: 5px 0px 0px 5px;
}

.user-product__look-btn-active {
    background: aliceblue;
}

.user-product__look-btn-rigth {
    border-radius: 0px 5px 5px 0px;
}

.user-product__look-btn:hover {
    background: aliceblue;
}

.user-product__cards {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
    margin-top: 15px;
    padding-left: 10px;
}

.user-product__card-btn-wrap {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-left: auto;
}
.user-product__card-btn--wrap {
    width: 25px;
    height: 25px;
}

.user-product__card-btn {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10;
    transition: .3s;
}

.user-product__card-btn-hide {
    
}
.user-product__card-btn-edite {
    
}
.user-product__card-btn-delete {
    
}

.user-product__card-btn-delete--hover,
.user-product__card-btn-edite--hover,
.user-product__card-btn-hide--hover {
    display: none;
}

.user-product__card-btn--wrap:hover .user-product__card-btn-delete,
.user-product__card-btn--wrap:hover .user-product__card-btn-edite,
.user-product__card-btn--wrap:hover .user-product__card-btn-hide {
    display: none;
}

.user-product__card-btn--wrap:hover .user-product__card-btn-delete--hover,
.user-product__card-btn--wrap:hover .user-product__card-btn-edite--hover,
.user-product__card-btn--wrap:hover .user-product__card-btn-hide--hover {
    display: block;
}

.user-product__card-btn:disabled {
    opacity: .5;
}

.user-product__product-missing {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    height: 450px;
}  

.user-product__list-cards {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    min-height: calc(100vh - 429px);
}

.user-product__list-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    padding: 6px;
    cursor: pointer;
}

.user-product__list-card-text {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.user-product__list-card-name {
    font-weight: 700;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.user-product__list-card-price-wrap {
    width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.user-product__list-card-new-price {
    font-size: 18px;
    font-weight: 700;
}

.user-product__list-card-price {
    font-size: 18px;
    font-weight: 700;
}

.user-product__list-card-price-old {
    font-size: 15px;
    font-weight: 500;
}

.user-product__list-card-category-wrap {
    width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.user-product__list-card-category-title {
    color: #171717ad;
}

.user-product__list-card-category {

}


@media (min-width: 576px) {
    .user-product {
        font-size: 16px;
    }
    .user-product__sub-title-wrap {
        margin-top: 28px;
        margin-bottom: 28px;
        gap: 20px;
    }
    .user-product__sub-title-btn {
        padding: 10px 15px;
    }
    .user-product__filter-wrap {
        padding-top: 28px;
        gap: 20px;
        padding-bottom: 28px;
    }
    .user-product__filter-input .MuiInputBase-root {
        border-radius: 0;
    }
    .user-product__filter-input .MuiInputBase-input  {
        padding: 15px 15px;
    }
    .user-product__filter-btn {
        height: 53px;
    }
    .user-product__cards {
        gap: 15px;
        margin-top: 45px;
        padding-left: 18px;
    }
    .user-product__list-card {
        gap: 15px;
        padding: 15px 15px;
    }
}

@media (min-width: 768px) {
    .user-product__sub-title-wrap {
        padding-left: 70px;
        padding-right: 70px;
    }
}