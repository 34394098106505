.input-number {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.input-number__label {
    font-weight: 600;
}

.input-number__input {
    padding: 10px 20px;
    max-width: 100px;
    font-size: 14px;
    line-height: 17px;
    color: #333;
    outline: none;
    height: fit-content;
    border-radius: 8px;
    background: #f6f7fa;
    border: 1px solid #E0E0E0;
}