:root {
    --bgColorTopBlockHeader: #ffffff;
    --colorTopBlockHeader: #000000;
    --bgColorHeader: #4e4848;
    --colorHeaderText: #ffffff;
    --bgNewProductHeader: #f71111;
    --colorNewProductHeader: #ffffff;
    --bgSubCategories: #4e4848;
    --colorSubCategoriesText: #ffffff;
    --bgColorFooter: #F0F0F2;
    --colorFooterText: #000000;
    --bgColorMain: #ffffff;
    --colorMainText: #000000;

/* -------Btn------- */
    --colorTextBtn: #ffffff;
    --colorTextBtnHover: #ffffff;
    --firstColorGradient: #060e83;
    --secondColorGradient: #060e83;
    --firstColorGradientHover: #060e83;
    --secondColorGradientHover: #060e83;
}

.app {
   
}

/* ------Стилі для кнопки------------- */

.app__custom-btn {
    min-width: 200px;
    width: fit-content;
    white-space: nowrap;
    height: 40px;
    border-radius: 5px;
    padding: 10px 40px;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
        box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
        7px 7px 20px 0px rgba(0,0,0,.1),
        4px 4px 5px 0px rgba(0,0,0,.1);
    outline: none;
}

.app__custom-btn-span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 2;
    white-space: nowrap;
    /* color: var(--colorTextBtn); */
    color: inherit;
}

/* 1 */
.app__btn-1 {
    background: rgb(6,14,131);
    background: linear-gradient(0deg, var(--firstColorGradient) 0%, var(--secondColorGradient) 100%);
    border: none;
    color: var(--colorTextBtn);
}
.app__btn-1:hover {
    background: rgb(6,14,131);
    background: linear-gradient(0deg, var(--firstColorGradientHover) 0%, var(--secondColorGradientHover) 100%);
    color: var(--colorTextBtnHover);
}

/* 2 */
.app__btn-2 {
    background:  rgb(6,14,131);
    background: linear-gradient(0deg, var(--firstColorGradient) 0%, var(--secondColorGradient) 100%);
    border: none;
    color: var(--colorTextBtn);
}
.app__btn-2:before {
    height: 0%;
    width: 2px;
}
.app__btn-2:hover {
    color: var(--colorTextBtnHover);
    box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
                -4px -4px 6px 0 rgba(116, 125, 136, .5), 
        inset -4px -4px 6px 0 rgba(255,255,255,.2),
        inset 4px 4px 6px 0 rgba(0, 0, 0, .4);
    background: linear-gradient(0deg, var(--firstColorGradientHover) 0%, var(--secondColorGradientHover) 100%);
}

/* 3 */
.app__btn-3 {
    background: rgb(6,14,131);
    background: linear-gradient(0deg, var(--firstColorGradient) 0%, var(--secondColorGradient) 100%);
    height: 40px;
    line-height: 42px;
    padding: 0;
    border: none;
    color: var(--colorTextBtn);
}
.app__btn-3 span {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
}
.app__btn-3:before,
.app__btn-3:after {
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    background: var(--firstColorGradientHover);
    transition: all 0.3s ease;
}
.app__btn-3:before {
    height: 0%;
    width: 2px;
}
.app__btn-3:after {
    width: 0%;
    height: 2px;
}
.app__btn-3:hover{
    color: var(--colorTextBtnHover);
    background: transparent;
    box-shadow: none;
}
.app__btn-3:hover:before {
    height: 100%;
}
.app__btn-3:hover:after {
    width: 100%;
}
.app__btn-3 span:hover{
    color: var(--colorTextBtnHover);
}
.app__btn-3 span:before,
.app__btn-3 span:after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    background: var(--secondColorGradientHover);
    transition: all 0.3s ease;
}
.app__btn-3 span:before {
    width: 2px;
    height: 0%;
}
.app__btn-3 span:after {
    width: 0%;
    height: 2px;
}
.app__btn-3 span:hover:before {
    height: 100%;
}
.app__btn-3 span:hover:after {
    width: 100%;
}

/* 4 */
.app__btn-4 {
    /* width: 130px; */
    height: 40px;
    line-height: 42px;
    /* padding: 0; */
    border: none;
    background: rgb(6,14,131);
    color: var(--colorTextBtn);
    background: linear-gradient(0deg, var(--firstColorGradient) 0%, var(--secondColorGradient) 100%);
}
.app__btn-4:hover {
    color: var(--colorTextBtnHover);
    background: transparent;
    box-shadow:none;
}
.app__btn-4:before,
.app__btn-4:after{
    content:'';
    position:absolute;
    top:0;
    right:0;
    height:2px;
    width:0;
    background:var(--firstColorGradientHover);
    box-shadow:
        -1px -1px 5px 0px #ffffff,
        7px 7px 20px 0px #0003,
        4px 4px 5px 0px #0002;
    transition:400ms ease all;
}
.app__btn-4:after{
    right:inherit;
    top:inherit;
    left:0;
    bottom:0;
}
.app__btn-4:hover:before,
.app__btn-4:hover:after{
    width:100%;
    transition:800ms ease all;
}

/* 5 */
.app__btn-5 {
    border: none;
    transition: all 0.3s ease;
    overflow: hidden;
    color: var(--colorTextBtn);
    /* background: var(--colorTextBtnHover); */
    background: transparent;
}
.app__btn-5:after {
    position: absolute;
    content: " ";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(6,14,131);
    background-image: linear-gradient(315deg, var(--firstColorGradient) 0%, var(--secondColorGradient) 74%);
    transition: all 0.3s ease;
}
.app__btn-5:hover {
    /* background: transparent; */
    box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
    -4px -4px 6px 0 rgba(116, 125, 136, .2), 
    inset -4px -4px 6px 0 rgba(255,255,255,.5),
    inset 4px 4px 6px 0 rgba(116, 125, 136, .3);
    color: var(--colorTextBtnHover);
}
.app__btn-5:hover:after {
    background: var(--firstColorGradientHover);
    -webkit-transform: scale(2) rotate(180deg);
    transform: scale(2) rotate(180deg);
    box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
                -4px -4px 6px 0 rgba(116, 125, 136, .2), 
        inset -4px -4px 6px 0 rgba(255,255,255,.5),
        inset 4px 4px 6px 0 rgba(116, 125, 136, .3);
}

/* 6 */
.app__btn-6 {
    background: rgb(22,9,240);
    background: linear-gradient(0deg, var(--firstColorGradient) 0%, var(--secondColorGradient) 100%);
    color: var(--colorTextBtn);
    border: none;
    transition: all 0.3s ease;
    overflow: hidden;
}
.app__btn-6:after {
    position: absolute;
    content: " ";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
    -webkit-transform: scale(.1);
    transform: scale(.1);
}
.app__btn-6:hover {
    color: var(--colorTextBtnHover);
    border: none;
    background: transparent;
}
.app__btn-6:hover:after {
    background: rgb(0,3,255);
    background: linear-gradient(0deg, var(--firstColorGradientHover) 0%,  var(--secondColorGradientHover) 100%);
    -webkit-transform: scale(1);
    transform: scale(1);
}  

/* 7 */
.app__btn-7 {
    background: linear-gradient(0deg, var(--firstColorGradient) 0%, var(--secondColorGradient) 100%);
    border: none;
    color: var(--colorTextBtn);
    z-index: 1;
}
.app__btn-7:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    z-index: -1;
    border-radius: 5px;
    background-color: rgb(6,14,131);
    background-image: linear-gradient(315deg, var(--firstColorGradientHover) 0%, var(--secondColorGradientHover) 74%);
        box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5) 
        7px 7px 20px 0px rgba(0,0,0,.1),
        4px 4px 5px 0px rgba(0,0,0,.1);
    transition: all 0.3s ease;
}
.app__btn-7:hover {
    color: var(--colorTextBtnHover);
}
.app__btn-7:hover:after {
    top: auto;
    bottom: 0;
    height: 100%;
}
.app__btn-7:active {
    top: 2px;
}

/* 8 */
.app__btn-8 {
    border: 2px solid black;
    background-color: white;
    cursor: pointer;
    border-color: var(--firstColorGradient);
    color: var(--colorTextBtn);
    transition: .3s;
}  
.app__btn-8:hover {
    background: linear-gradient(0deg, var(--firstColorGradientHover) 0%, var(--secondColorGradientHover) 100%);
    color: var(--colorTextBtnHover);
}