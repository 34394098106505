.main-slider {
    padding: 10px;
}

.main-slider__title {
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 21px;
    color: #4D5E80;
}

.main-slider__create-img-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border: 3px dashed #E5E5E5;
    border-radius: 6px;
    height: 230px;
    text-align: center;
    padding: 5px 10px;  
    margin-left: auto;
    margin-right: auto;
}

.main-slider__create-img {
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
}

.main-slider__create-img-link-upload-input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    top: 0; 
}

.main-slider__create-img-link-upload-lable {
    font-weight: 600;
    color: #4C6FFF;
    cursor: pointer;
}

.main-slider__result {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.main-slider__result-list-wrap {
    display: flex;
    gap: 20px;
    margin-top: 13px;
    margin-bottom: 13px;
    width: 100%;
}

.main-slider__result-list {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    width: 49%;
    border-radius: 8px;
    border: 1px solid #E5E5E5;
}

.main-slider__result-list-img {
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: contain;
    border-top: 1px solid #E5E5E5;
}

.main-slider__result-list-btn {
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 50%;
    padding: 3px;
    transition: .3s;
}

.main-slider__result-list-btn:hover {
    background-color: rgb(120 78 78);
}