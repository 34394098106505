.search-product {
    margin-top: 20px;
    margin-bottom: 20px;
}

.search-product--wrap {

}

.search-product__cards {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.search-product__error-text {
    width: 100%;
    text-align: center;
    font-size: 22px;
    margin-top: 162px;
    margin-bottom: 162px;
}