.shop-templates {
    padding-bottom: 30px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    background: #fff;
    width: 100%;
    min-height: 100vh;
}

.shop-templates--shop {
    position: static;
}

.shop-templates__logo-wrap {
    padding: 15px 0;
    border-bottom: 1px solid rgba(180, 182, 197, 0.38);
}

.shop-templates__logo {
    width: 37px;
    height: 55px;
}

.shop-templates--wrap {
    height: 100%;
}

.shop-templates__scale-wrap {
    position: relative;
    margin-top: 50px;
    margin-bottom: 40px;
    width: 327px;
    height: 8px;
    margin-left: auto;
    margin-right: auto;
}
.shop-templates__scale-black {
    position: absolute;
    top: 0;
    left: 0;
    height: 8px;
    border-radius: 4px;
    background: #3E3E3E;
}

.shop-templates__scale-black--4 {
    width: 100% !important;
}

.shop-templates__scale {
    background: #E8E9F1;
    border-radius: 4px;
    width: 100%;
    height: 100%;
}

.shop-templates__title {
    text-align: center;
    font-weight: 500;
    font-size: 32px;
    margin-bottom: 15px;
    color: #6D7580
}

.shop-templates__sub-title {
    text-align: center;
    font-size: 24px;
    color: #858C94;
    margin-bottom: 35px;
}

.shop-templates__input-wrap {
   display: flex;
   justify-content: center;
   align-items: center;
}

.shop-templates__input-wrap .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root, .css-xn6ruc {
    width: 350px;
}

.shop-templates__btn-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 37px;
    margin-bottom: 37px;
}

.shop-templates__next-btn {
    background: #FFFFFF;
    border: 2px solid #cdd2d7;
    box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
    border-radius: 20px;
    color: #6B7A99;
    padding: 10px 20px;
    transition: .3s;
}

.shop-templates__next-btn:hover {
    background: #6B7A99;
    color: #fff;
    scale: 1.15;
}

.shop-templates__modal-templates {
    width: 100%;
    height: 100%;
}

.shop-templates__modal-swiper {
    width: 100%;
    height: 100%;
    padding-top: 33px;
    padding-bottom: 20px;
}

.shop-templates__modal-swiper .swiper-button-prev {
    top: 6px;
    left: auto;
    right: 86px;
}

.shop-templates__modal-swiper .swiper-button-prev::after {
    content: "\2190" !important;
    width: 36px;
    height: 36px;
    transition: .3s;
    color: var(--colorMainText);
}

.shop-templates__modal-swiper .swiper-button-prev:hover::after {
    transform: translateX(-10px);
}

.shop-templates__modal-swiper .swiper-button-next {
    top: 6px;
    right: 23px;
}

.shop-templates__modal-swiper .swiper-button-next::after {
    content: "\2192" !important;
    width: 36px;
    height: 36px;
    transition: .3s;
    color: var(--colorMainText);
}

.shop-templates__modal-swiper .swiper-button-next:hover::after {
    transform: translateX(10px);
}


.shop-templates__modal-templates-img {
    object-fit: contain;
    border: 4px solid #eee;
}

.shop-templates--wrap {

}

.shop-templates__cards {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
    align-items: center;
}

.shop-templates__card {
    width: 350px;
    background: #FFFFFF;
    box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
    border-radius: 16px;
    object-fit: contain;
    min-width: 300px;
    padding: 8px;
}

.shop-templates__card-select {
    background: #66aeefed;
}

.shop-templates__card-info {
    padding: 16px;
}

.shop-templates__card-title {
   color: #16192C;
   font-weight: 600;
    margin-top: 8px;
    margin-bottom: 16px;
}

.shop-templates__card-text {
    font-size: 14px;
    color: #425466;
    margin-bottom: 24px;
}

.shop-templates__card-img {
    cursor: pointer;
    width: 100%;
    max-height: 278px;
    cursor: pointer;
    object-fit: contain;
}

.shop-templates__card-img-none {
    padding: 61px 83px;
    background: #EDF2F7;
    height: 278px;
}