body {
  /* font-family: OpenSansRegular,-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: OpenSansRegular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  /* overflow: hidden; */
  /* background: #000000; */
}

@font-face {
  font-family: OpenSansMedium;
  src: url(fonts/OpenSans/OpenSans-Medium.ttf);
}
@font-face {
  font-family: OpenSansBold;
  src: url(fonts/OpenSans/OpenSans-Bold.ttf);
}
@font-face {
  font-family: OpenSansRegular;
  src: url(fonts/OpenSans/OpenSans-Regular.ttf);
}
@font-face {
  font-family: OpenSansSemiBold;
  src: url(fonts/OpenSans/OpenSans-Bold.ttf);
}

.hidden {
  overflow: hidden;
}

*,
::after,
::before {
  box-sizing: border-box;
}
/* *:last-child {
  margin-bottom: 0;
} */
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}
ul,
ol,
li {
  margin: 0;
  padding: 0;
  list-style: none;
  color: inherit;
}
a {
  display: inline-block;
  text-decoration: none;
  color: inherit;
}
button,
label,
input {
  padding: 0;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  background-color: inherit;
  border: none;
  outline: none;
  cursor: pointer;
}
img,
svg {
  /* margin: 0 auto; */
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  object-position: center;
}

.container {
  margin: 0 auto;
  padding: 0 10px;
  width: 100%;
  /* max-width: 1200px; */
  max-width: 1400px;

  /* background: #00ff08; */
}

@media (min-width: 576px) {
  .container {
    padding: 0 15px;
  }

}

@media (min-width: 768px) {
  
}

@media (min-width: 1080px) {
 
}