.read-purchases {
    color: #6B7A99;
}

.read-purchases--wrap {

}

.read-purchases__banned {
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 10px;
    padding: 50px 0;
    background: #E31E24;
}

.read-purchases__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    color: #6B7A99;
    font-weight: 700;
    padding: 30px 10px 30px 70px;
    border-bottom: 2px solid #F2F3F5;
}

.read-purchases__header-img {
    width: 40px;
    height: 40px;
}

.read-purchases__header-title {
    margin-bottom: 3px;
    white-space: nowrap;
}

.read-purchases__header-btn-wrap {
    padding: 22px 0;
    padding: 23px 10px 23px 70px;
    border-bottom: 2px solid #F2F3F5;
}

.read-purchases__header-btn {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #7D8FB3;
    font-size: 14px;
    font-weight: 400;
}

.read-purchases__header-btn-img {
    width: 20px;
    height: 20px;
}

.read-purchases-wrap {
    padding: 20px 0 20px 20px;
}

.read-purchases__info {
    background: #FFFFFF;
    box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
    border-radius: 16px;
}

.read-purchases__info--wrap {
    padding: 15px;
    
}

.read-purchases__info-name {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 11px;
}

.read-purchases__info-status-wrap {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 5px;
}

.read-purchases__info-id {
    color: #4C6FFF;
    font-weight: 600;
}

.read-purchases__status {
    border-radius: 5px;
    padding: 2px 10px 4px;
    width: 125px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.read-purchases__status--in_process {
    color: #4C6FFF;
    border: 1px solid #4C6FFF;
}
.read-purchases__status--done {
    color: #7FC008;
    border: 1px solid #7FC008;
}
.read-purchases__status--rejected {
    color: #DB303F;
    border: 1px solid #DB303F;
}

.read-purchases__info-date {
    color: #ADB8CC;
    font-weight: 600;
    margin-bottom: 17px;
}

.read-purchases__info-text-wrap { 
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
}

.read-purchases__info-text--wrap { 
    display: flex;
    align-items: center;
    gap: 15px;
    min-width: 120px;
}

.read-purchases__info-text-img-wrap {
    width: 18px;
    height: 18px;
}

.read-purchases__info-text-img {
    object-fit: contain;
}

.read-purchases__info-text {
    color: #4D5E80;
    font-size: 13px;
    white-space: nowrap;
}

.read-purchases__info-comment-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
    padding: 25px 15px;
    border-top: 2px solid #F5F6F7;
    border-bottom: 2px solid #F5F6F7;
}

.read-purchases__info-comment--wrap {
}

.read-purchases__info-comment-title {
    color: #344054;
    font-size: 14px;
    font-weight: 400;
}

.read-purchases__info-comment {
    color: #4D5E80;
    font-size: 13px;
    font-weight: 600;
}

.read-purchases__info-select-status {
    min-width: 200px;
}

.read-purchases__info-notes-wrap {
    padding: 15px 15px 25px;
}

.read-purchases__cards {
    display: flex;
    gap: 25px;
    flex-wrap: wrap;
    margin-top: 32px;
}

.read-purchases__card {
    box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
    border-radius: 16px;
    padding: 10px;
    background: #FAFBFC;
}

.read-purchases__promo-wrap {
    margin-top: 15px;
    margin-bottom: 15px;
}

.read-purchases__product-error {
    margin-top: 25px;
    margin-bottom: 25px;
}

.read-purchases__count-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
}

.read-purchases__count {
    display: flex;
    align-items: center;
}

.read-purchases__count-title {
    margin-right: 4px;
}

.read-purchases__count-price {
}

.read-purchases__count-price-title {
    margin-right: 4px;
}

.read-purchases__btn-edite {
    width: 40px;
    height: 40px;
    margin-left: 8px;
    cursor: pointer;
    border-radius: 50%;
    padding: 3px;
    transition: .3s;
}

.read-purchases__btn-edite:hover {
    background-color: rgb(104 100 100);
}

.read-purchases__card-btn-delete-wrap {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-top: 12px;
    margin-bottom: 12px;
}

.read-purchases__card-btn-delete {
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 50%;
    padding: 3px;
    transition: .3s;
}

.read-purchases__card-btn-delete:hover {
    background-color: rgb(104 100 100);
}

.read-purchases__disable {
    pointer-events: none;
    opacity: .5;
}

.read-purchases__card-size-wrap {
    
}

.read-purchases__card-size-title-wrap {
    display: flex;
    align-items: center;
}

.read-purchases__card-size-title {

}

.read-purchases__card-size-text {

}

.read-purchases__card-color-wrap {
    margin-bottom: 15px;
}

.read-purchases__card-color-title-wrap {
    display: flex;
    align-items: center;
}

.read-purchases__card-color-title {

}

.read-purchases__card-color-text {
    display: flex;
    gap: 3px;
    flex-wrap: wrap;
}

.read-purchases__modal-color-wrap {
    padding: 3px;
    cursor: pointer;
}

.read-purchases__modal-color-select-wrap {
    background: #66db5c;
}

.read-purchases__modal-color--wrap {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    gap: 5px;
    min-height: 74px;
}

.read-purchases__modal-color-title {
    color: #171717ad;
}