.merchant-instruction {

}

.merchant-instruction--wrap {
    
}

.merchant-instruction__public-offer {
    width: 100vw;
    height: 100vh;
}