.select-card {
    padding: 0 10px;
}

.select-card__title {
    /* text-align: center; */
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 21px;
    font-weight: 600;
    color: #4D5E80;
}

.select-card__result-wrap {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
}

.select-card__result-select {
    padding: 20px;
    /* background: greenyellow; */
    /* cursor: pointer; */
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    min-height: 643px;
    box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
    border-radius: 16px;
}

/* .select-card__result-select-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 700;
    min-height: 25px;
} */

.select-card__result-select-card-none {
    /* display: none; */
    opacity: 0;
}

.select-card__result-btn-wrap {
    display: flex;
    gap: 20px;
    align-items: center;
}

.select-card__result-btn {
    display: flex;
    align-items: center;
    gap: 13px;
    background: #4C6FFF;
    border-radius: 6px;
    padding: 12px 20px;
    color: #fff;
    /* margin-left: auto; */
    font-size: 14px;
    transition: .3s;
}

.select-card__result-btn:hover {
    background: #6B7A99;
}

.select-card__result-select-card {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #4C6FFF;
}

.select-card__result-select-card-img {
    width: 18px;
    height: 18px;
}

.select-card__error-text {
    text-align: center;
    margin-top: 80px;
    margin-bottom: 80px;
    font-size: 22px;
    font-weight: 700;
}
@media (min-width: 576px) {

}

@media (min-width: 768px) {
    
}

@media (min-width: 1080px) {

}