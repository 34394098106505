.creation-shop {
    padding-top: 15px;
    padding-bottom: 15px;
    flex: 1 1;
    padding: 20px 20px 15px;
}

.creation-shop--wrpa {
    display: flex;
    gap: 15px;
    padding-bottom: 50px;
    background: #fff;
    border-radius: 8px;
    padding: 20px 30px 15px;
}

.creation-shop__create--wrap {
    flex: 1 1;
}

.creation-shop__create-wrap {
    margin-bottom: 25px;
}

.creation-shop__create-title {
    position: relative;
    margin-bottom: 18px;
}

/* .creation-shop__create-title-name,
.creation-shop__create-title-price,
.creation-shop__create-title-category {
    display: inline-block;
}

.creation-shop__create-title-name::after,
.creation-shop__create-title-price::after,
.creation-shop__create-title-category::after {
    content: '*';
    position: absolute;
    top: 0;
    right: -10px;
    color: #E31E24;
} */

.creation-shop__create-input {
    width: 100%;
    max-width: 500px;
}

.creation-shop__create-name-sub-title {
    margin-top: 3px;
}

.creation-shop__btn-cancel-sizes-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.creation-shop__create-sizes-btn {
    background: #4C6FFF;
    border-radius: 6px;
    color: #fff;
    width: 49px;
    height: 42px;
    transition: .3s;
}

.creation-shop__create-sizes-btn:hover {
    background: #6B7A99;
}

.creation-shop__create-result-sizes-wrap {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 15px;
}

.creation-shop__create-result-sizes {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    background: #EBEEF2;
    border-radius: 100px;
    padding: 3px 2px 3px 7px;
}

.creation-shop__create-result-sizes-text {

}

.creation-shop__create-result-sizes-btn {
    width: 14px;
    height: 13px;
    cursor: pointer;
}

.creation-shop__section-input-check-label {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-right: 20px;
}

.creation-shop__section-logo-img-wrap {
    position: relative;
}

.creation-shop__section-logo-img {
    width: 21vmin;
    height: 85px;
    display: inline-block;
    object-fit: contain;
    margin-left: 35px;
}

.creation-shop__create-img--wrap {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 40%;
}

.creation-shop__create-img-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border: 3px dashed #E5E5E5;
    border-radius: 6px;
    height: 230px;
    text-align: center;
    padding: 5px 10px;
}

.creation-shop__create-img-text-wrap {
    position: relative;
}

.creation-shop__create-img-link-upload-input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    top: 0;
}

.creation-shop__create-img-link-upload-lable {
    color: rgba(76, 111, 255, 1);
    cursor: pointer;
}

.creation-shop__section-logo-img-delete {
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.creation-shop__section-logo-img-edite {
    position: absolute;
    top: 30px;
    left: 0;
    width: 30px;
    height: 30px;
}

.creation-shop__delete-btn {
    letter-spacing: 0.25px;
    color: #DB303F;
    font-size: 14px;
}