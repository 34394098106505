.user-integration {
    padding-bottom: 15px;
    flex: 1 1;
    font-size: 12px;
}

.user-integration__header {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: 2px solid #F2F3F5;
    min-height: 85px;
}

.user-integration__header-title {
    display: flex;
    gap: 20px;
    align-items: center;
    color: #6B7A99;
    font-weight: 700;
}

.user-integration__header-title-img {
    width: 20px;
    height: 20px;
}

.user-integration__header-title-text {
    white-space: nowrap;
}

.user-integration__header-tab-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-integration--wrap {
    padding-left: 15px;
}

.user-integration__chat-create-wrap {
    padding: 20px 7px;
    border-radius: 16px;
    box-shadow: 2px 2px 8px rgb(0 0 0 / 52%);
}

.user-integration__chat-title-img {
    width: 160px;
    height: 20px;
    margin-top: 35px;
    margin-bottom: 10px;
    margin-left: 15px;
}

.user-integration__chat-text {
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 15px;
    color: #7A7A9D;
}

.user-integration__chat-img {
    width: 100%;
    object-fit: contain;
    border-radius: 16px;
    margin-bottom: 20px;
}

.user-integration__chat-img-zoom {
    position: absolute;
    top: -25px;
    left: 0px;
    width: 100vw;
    object-fit: contain;
    z-index: 5;
    height: 100vh;
}

.user-integration__chat-create-title {
    color: #16192C;
    margin-bottom: 15px;
}

.user-integration__chat-create-sub-title {
    color: #16192C;
    font-size: 14px;
    margin-bottom: 15px;
}

.user-integration__chat-create-input-wrap {
    position: relative;
    border-radius: 10px;
}

.user-integration__chat-create-input-wrap .MuiOutlinedInput-notchedOutline {
    border-radius: 10px;
    background: #EDF2F7;
}

.user-integration__chat-create-input {
    width: 100%;
    border-radius: 10px;
}

.user-integration__chat-create-input .MuiInputBase-input {
    z-index: 2;
}

.user-integration__chat-create-input-btn {
    background: #4C6FFF;
    border-radius: 6px; 
    padding: 12px 20px;
    color: #fff;
    z-index: 2;
    margin-top: 13px;
    transition: .3s;
}

.user-integration__chat-create-input-btn:hover {
    background: #6B7A99;
}

.user-integration__chat-linck {
    color: #0d48ac;
}

.user-integration__error-create {
    color: red;
    margin-top: 15px;
    margin-bottom: 15px;
}

.user-integration__chat-res {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}

.user-integration__chat-res-text {
}

.user-integration__chat-res-linck-wrap {
    display: flex;
    gap: 5px;
    align-items: center;
    width: 100%;
}

.user-integration__chat-res-linck {
    overflow: hidden;
    font-weight: 700;
}

.user-integration__chat-res-del-btn {
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 50%;
    padding: 3px;
    transition: .3s;
}

.user-integration__chat-res-del-btn:hover {
    background-color: rgb(161, 157, 157);
}

/* ----------------------- */
.user-integration__merchant-wrap {
    padding: 0 15px;
}

.user-integration__merchant-img {
    width: 200px;
    margin-left: auto;
    margin-right: auto;
}

.user-integration__merchant-text {
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 15px;
    color: #7A7A9D;
}
.user-integration__merchant-input-lable {
    margin-top: 10px;
    margin-bottom: 5px;
    margin-left: 15px;
    color: #7A7A9D;
}

.user-integration__merchant-linck {
    color: #0d48ac;
}

.user-integration__merchant-scrin-img {
    width: 100%;
}

@media (min-width: 576px) {
    .user-integration {
        font-size: 16px;
    }
    .user-integration__header {
        padding-left: 70px;
        padding-right: 70px;
    }
    .user-integration__chat-create-wrap {
        padding: 30px 15px;
    }
    .user-integration__chat-text {
        margin-bottom: 60px;
    }
    .user-integration__chat-create-input-btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5px;
        margin-top: 0;
    }
}