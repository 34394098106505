
.motivation-block-wrap {
    width: 100%;
    height: 550px;
    background-color: #04befe;
}

.motivation-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.motivation-block__title {
    color: #ffffff;
    font-size: 24px;
    font-weight: bold;
    max-width: 60%;
    text-align: center;
    margin-bottom: 25px;
    letter-spacing: 5px;
    text-transform: uppercase;
}

.motivation-block__btn {
    color: #102245;
    font-size: 18px;
    font-weight: 500;
    border-width: 1px;
    border-radius: 8px;
    background-color: #fcdf37;
    padding: 15px 47px;
    transition: .3s;
}

.motivation-block__btn:hover {
    background-color: rgba(0, 0, 0, 0.597);
    color: #fff;
}


@media (min-width: 576px) {
    .motivation-block__title {
        font-size: 32px;
    }
}

@media (min-width: 768px) {
    .motivation-block__title {
        font-size: 40px;
    }
}

@media (min-width: 1080px) {
    .motivation-block__title {
        font-size: 60px;
    }
}