.client-view-wrap {
}

.client-view {
    padding-bottom: 53px;
    height: calc(100vh - 481px);
}

.client-view__title {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    margin-top: 25px;
    margin-bottom: 25px;
}