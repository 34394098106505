.promo-code {
    padding: 0px 10px;
}

.promo-code__modal-edit-input-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 7px;
    align-items: center;
}

.promo-code__modal-edit-label {

}

.input-number__input {
    background: white;
}

.promo-code__modal-edit-input {
    padding: 10px 20px;
    max-width: 100px;
    font-size: 14px;
    line-height: 17px;
    color: #333;
    outline: none;
    height: fit-content;
    border-radius: 8px;
    background: white;
    border: 1px solid #065bea;
    margin-top: 5px;
    margin-bottom: 5px;
}

.promo-code__create {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    color: #444;
    padding: 8px 16px;
    width: 100%;
    text-align: left;
    font-size: 15px;
    margin-top: 8px;
    margin-bottom: 20px;
    gap: 15px;
}

.promo-code__create-input--wrap {
    margin-bottom: 25px;
}

.promo-code__create-input-title {
    position: relative;
    margin-bottom: 8px;
}

.promo-code__create-input {
    width: 100%;
    max-width: 500px;
}

.promo-code__create-checkbox {
    display: inline-block;
    margin-left: 10px;
    margin-right: 5px;
}

.promo-code__create-checkbox-label {
    display: inline-block;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.promo-code__create-error {
    color: red;
}

.promo-code__create-btn-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 10px;
}

.promo-code__create-btn {
    border: 2px solid black;
    background-color: white;
    padding: 14px 28px;
    font-size: 16px;
    cursor: pointer;
    border-color: #0d48ac;
    color: #0d48ac;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: auto;
    display: block;
    transition: .3s;
}

.promo-code__create-btn:hover:not([disabled]) {
    background: #0d48ac;
    color: white;
}

.promo-code__created-promos {

}

.promo-code__created-promo-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-bottom: 15px;
    margin-top: 15px;
    padding: 10px;
    border: 1px solid #d0d2da;
    box-shadow: 0px 18px 32px 0px rgba(208, 210, 218, 0.15);
    border-radius: 8px;
}

.promo-code__created-promo {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    font-size: 16px;
    min-width: 230px;
}

.promo-code__created-promo-items {

}

.promo-code__created-promo-item {

}

.promo-code__created-promo-btn-wrap {
    display: flex;
    gap: 5px;
}

.promo-code__created-promo-del-btn {
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 50%;
    padding: 3px;
    transition: .3s;
}

.promo-code__created-promo-del-btn:hover {
}