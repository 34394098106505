.customers-view {
    color: #6B7A99;
}

.customers-view__modal-input-wrap {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.customers-view__modal-input {
    max-width: 500px;
    width: 100%;
}

.customers-view__header-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 30px 10px 30px 70px;
    border-bottom: 2px solid #F2F3F5;
}

.customers-view__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    font-weight: 700;
}

.customers-view__header-img {
    width: 40px;
    height: 40px;
}

.customers-view__header-title {
    margin-bottom: 3px;
    white-space: nowrap;
}

.customers-view__header-btn-wrap {
    display: flex;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 30px 10px 30px 70px;
    border-bottom: 2px solid #F2F3F5;
}

.customers-view__header-btn {
    display: flex;
    align-items: center;
    gap: 13px;
    background: #4C6FFF;
    border-radius: 6px;
    padding: 12px 20px;
    color: #fff;
    margin-left: auto;
    font-size: 14px;
    transition: .3s;
}

.customers-view__header-btn:hover {
    background: #6B7A99;
}

.customers-view__header-btn-img {
    width: 18px;
    height: 18px;
}

.customers-view__error {
    text-align: center;
    margin-top: 100px;
    font-size: 20px;
    font-weight: 500;
    color: #6D7580;
}

.customers-view__result-wrap {

}

.customers-view__result {
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid #F2F3F5;
}


.customers-view__filter {
    display: flex;
    align-items: center;
}

.customers-view__filter-input {
    
}

.customers-view__filter-input .MuiInputBase-root {
    border-radius: 0;
}

.customers-view__filter-input .MuiInputBase-input  {
    padding: 16px 8px;
}

.customers-view__filter-btn {
    border: 2px solid black;
    background-color: white;
    padding: 4px 28px;
    font-size: 16px;
    cursor: pointer;
    border-color: rgba(0, 0, 0, 0.23);
    color: #6B7A99;
    height: 54px;
    display: block;
    transition: .3s;
}

.customers-view__filter-btn:hover {
    background: #6B7A99;
    color: white;
}