.user-shop {
    min-width: 680px;
    flex: 1 1;
    font-size: 12px;
    color: #344054;
    padding-bottom: 30px;
    height: 100%;
    background: #FAFBFC;
}

.user-shop__header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap: 15px;
    flex-wrap: wrap;
    padding-left: 50px;
    padding-right: 15px;
    padding-top: 14px;
    border-bottom: 2px solid #F2F3F5;
    background: #fff;
}

.user-shop__header-title {
    display: flex;
    gap: 20px;
    align-items: center;
    color: #6B7A99;
    font-weight: 700;
}

.user-shop__header-title-img {
    width: 20px;
    height: 20px;
}   

.user-shop__header-title-text {
    white-space: nowrap;
}

.user-shop__header-tab-wrap {
    margin-left: auto;
    margin-right: auto;
    color: #27447e;
}

.user-shop__header-tab-wrap .MuiTabs-indicator {
    background-color: #5F41B2; 
}
.user-shop__header-tab-wrap .MuiButtonBase-root {
    padding: 32px 16px 32px;
}
.user-shop__header-tab-wrap .MuiButtonBase-root.Mui-selected {
    color: #5F41B2;
}

.user-shop__header-tab-wrap .MuiTabs-flexContainer {
    flex-direction: column;
}

.user-shop__header-tab-general-wrap {
    color: #27447e;
    border-bottom: 2px solid #F2F3F5;
    background: #fff;
}
.user-shop__header-tab-general-wrap .MuiTabs-indicator {
    background-color: #5F41B2; 
}
.user-shop__header-tab-general-wrap .MuiButtonBase-root {
    padding: 12px 16px 12px;
}
.user-shop__header-tab-general-wrap .MuiButtonBase-root.Mui-selected {
    color: #5F41B2;
}

.user-shop__header-btn-wrap {
    display: flex;
    align-items: center;
    gap: 20px;
}

.user-shop__header-btn {
    display: flex;
    align-items: center;
    gap: 13px;
    background: #4C6FFF;
    border-radius: 6px;
    padding: 12px 20px;
    color: #fff;
    margin-left: auto;
    font-size: 14px;
    transition: .3s;
}

.user-shop__header-btn:hover {
    background: #6B7A99;
}

.user-shop__header-btn-img {
    width: 18px;
    height: 18px;
}

.user-shop__header-tab-setting-wrap {
    margin-bottom: 10px;
    color: #27447e;
}

.user-shop__header-tab-setting {
    display: flex;
    align-items: start;
    gap: 4px;
}

.user-shop__header-tab-setting-img {
    width: 18px;
    height: 18px;
    margin-right: 7px;
}

.user-shop__header-tab-setting-img1 {
    display: none;
    width: 18px;
    height: 18px;
    margin-right: 7px;
}
 
.user-shop__header-tab-setting-wrap .Mui-selected {
    color: #5F41B2;
}

.user-shop__header-tab-setting-wrap .MuiTabs-indicator {
   background-color: #5F41B2; 
}

.user-shop__header-tab-setting-wrap .Mui-selected .user-shop__header-tab-setting-img {
    display: none;
}

.user-shop__header-tab-setting-wrap .Mui-selected .user-shop__header-tab-setting-img1 {
    display: block;
}

/* -------------1--------- */

.user-shop__section-wrap {
    padding: 20px 20px;
    margin-bottom: 15px;
}

.user-shop__section {
    padding: 20px 30px;
    background: #FFFFFF;
    box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);
    border-radius: 8px;
}

.user-shop__section-input-wrap {
    width: 100%;
}

.user-shop__create-wrap {
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: baseline;
}

.user-shop__create-title-wrap {
    width: 48%;
}

.user-shop__create-title {
    position: relative;
    margin-bottom: 8px;
}

.user-shop__create-input {
    width: 100%;
    max-width: 300px;
}

.user-shop__create-input-title {
    min-width: 300px;
}

.user-shop__create-input-information {
    width: 100%;
    max-width: 500px;
}

.user-shop__create-test-wrap {
    display: flex;
    flex-direction: column;
    padding-top: 7px;
    gap: 10px;
    width: 100%;
}

.user-shop__create-test-btn {
    background: #4C6FFF;
    border-radius: 6px;
    padding: 12px 20px;
    color: #fff;
    margin-right: auto;
    font-size: 14px;
    transition: .3s;
}

.user-shop__create-test-btn:hover {
    background: #6B7A99;
}

.user-shop__create-test-img {
    width: 100%;
    height: 500px;
}

.user-shop__header-btn-input-upload {
    width: 0;
    height: 0;
    opacity: 0;
}

.user-shop__section-setting-wrap {
    display: flex;
    gap: 25px;
    flex-wrap: wrap;
}

.user-shop__section-setting-1 {
}

.user-shop__section-setting-2 {
}

.user-shop__section-setting-title {
    margin-top: 20px;
    margin-bottom: 10px;
}

.user-shop__section-setting-blocks-wrap {
    display: flex;
    align-items: center;
    gap: 25px;
}


.user-shop__section-setting-blocks {

}

.user-shop__section-header-color-bg {
    pointer-events: none;
    opacity: 0.4;
}

.user-shop__section-setting-blocks-colum-wrap {
    display: flex;
    flex-direction: column;
    gap: 15px;   
}

.user-shop__section-selection-button-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.user-shop__section-selection-button-select {
    margin-bottom: 3px;
    opacity: 0;
}

.user-shop__section-selection-button-selected {
    opacity: 1;
}

.user-shop__section-input-number-wrap {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 10px;
}


.user-shop__information-title {
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 10px;
}

.user-shop__information-block {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.user-shop__information-block-title {
    text-align: center;
    margin-bottom: 8px;
    text-transform: uppercase;
}

.user-shop__information-block-text {
    padding: 0 15px;
}

.user-shop__section-title-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.user-shop__section-title {
    font-size: 21px;
    font-weight: 600;
    color: #4D5E80;
}

.user-shop__section-advertising-checkbox-wrap {
    display: flex;
    gap: 5px;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    height: 750px;
    overflow: auto;
    padding: 5px;
}

.user-shop__section.user-shop__section-advertising {
    flex-direction: column;
    align-items: flex-start;
}

.user-shop__section-checkbox {
    
}

.user-shop__section-btn-checkbox-label-wrap .MuiButtonBase-root,
.user-shop__section-checkbox .MuiButtonBase-root {
    color: #7D8FB3;
}

.user-shop__section-btn-checkbox-label-wrap .MuiSwitch-track,
.user-shop__section-checkbox .MuiSwitch-track {
    background-color: #8b8585;
}

.user-shop__section-btn-checkbox-label-wrap .Mui-checked,
.user-shop__section-checkbox .Mui-checked {
    color: #5F41B2 !important;
}

.user-shop__section-btn-add-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 14px;
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 14px;
}

.user-shop__section-btn-checkbox-label-wrap {

}

.user-shop__section-btn-checkbox-label-text {
  color: #344054;   
}


.user-shop__token-wrap {
    padding: 10px 10px;
}


.user-shop__token-input-wrap {
    position: relative;
    border-radius: 10px;
    margin-top: 15px;
}

.user-shop__token-input-wrap .MuiOutlinedInput-notchedOutline {
    border-radius: 10px;
    background: #EDF2F7;
}

.user-shop__token-input {
    width: 100%;
    border-radius: 10px;
}

.user-shop__token-input-btn {
    background: #4C6FFF;
    border-radius: 6px; 
    padding: 12px 20px;
    color: #fff;
    z-index: 2;
    margin-top: 13px;
    transition: .3s;
}

.user-shop__token-input-btn:hover {
    background: #6B7A99;
}

.user-shop__token-new-btn {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #4C6FFF;
    margin-top: 10px;
}

/* ---------------------- */
.user-shop__result-shop-wrap {
    width: 100%;
    max-width: 1147px;
    border: 1px solid;
    overflow: auto;
}

.user-shop__result-shop--wrap {
    pointer-events: none;
}

.user-shop__preview-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 13px;
    width: 100%;
    background: #4C6FFF;
    border-radius: 6px; 
    padding: 12px 20px;
    color: #fff;
    margin-top: 13px;
    margin-bottom: 15px;
    transition: .3s;
}

.user-shop__preview-btn:hover {
    background: #6B7A99;
}

.user-shop__preview-btn-img {
    width: 25px;
    height: 16px;
}

@media (min-width: 450px) {
    .user-shop__header-tab-wrap .MuiTabs-flexContainer {
        flex-direction: row;
    }
}
@media (min-width: 576px) {
    .user-shop {
        font-size: 14px;
    }
    .user-shop__token-wrap {
        padding: 30px 15px;
    }
    .user-shop__token-input-btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5px;
        margin-top: 0;
    }
}
@media (min-width: 768px) {
    .user-shop {
        font-size: 16px;
    }  
}