.product-card {
    height: fit-content;
}
/* ===================== */
.product-card3--3 {
    position: relative;
    width: 100%;
    height: 100%;
    min-width: 180px;
    max-width: 237px;
    background: #ffffff;
    transition: .3s;
}

.product-card3--3:hover {
    box-shadow: 5px 5px 30px rgb(0 0 0 / 55%),
    -5px 0px 30px rgb(0 0 0 / 55%);
}

.product-card3--3:hover .product-card3__text-wrap {
    box-shadow: 5px 5px 30px rgb(0 0 0 / 55%),
              -5px 0px 30px rgb(0 0 0 / 55%);
}

.product-card3__img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    aspect-ratio: 9/12;
    border: 1px solid #E4E4E5;
    cursor: pointer;
    object-fit: contain;
}

.product-card3__img-none {
    width: 100%;
    aspect-ratio: 9/12;
    border: 1px solid #E4E4E5;
    object-fit: contain;
    padding: 10px;
    cursor: pointer;
    object-fit: contain;
}

.product-card3__img:hover {
    cursor: pointer;
}

.product-card3__text-wrap {
    position: absolute;
    bottom: -87px;
    left: 0;
    background: #fff;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 16px;
    border: 1px solid #E4E4E5;
    width: 100%;
    min-width: 180px;
    transition: .3s;
}

.product-card3__text-wrap:hover {
    box-shadow: 5px 5px 30px rgb(0 0 0 / 55%),
              -5px 0px 30px rgb(0 0 0 / 55%);
}

.product-card3__title {
    font-size: 16px;
    color: #000000;
    font-weight: bold;
    max-height: 44px;
    overflow: hidden;
}

.product-card3__title:hover {
    cursor: pointer;
}

.product-card3__details {
    position: relative;
    z-index: -2;
    height: 0;
    opacity: 0;
    transition: height 0.5s ease, opacity 0.5s ease;
}

.product-card3__text-wrap:hover .product-card3__details {
    z-index: 1;
    opacity: 1;
    height: 250px;
    padding-top: 10px;
    overflow: hidden;
}

.product-card3__bottom-wrap {
    display: flex;
    justify-content: space-between;
}

.product-card3__price-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    max-width: 117px;
    overflow: auto;
}

.product-card3__price {
    font-size: 24px;
    font-weight: 500;
    white-space: nowrap;
    color: #000000;
}

.product-card3__price--discount {
    text-decoration: line-through;
    font-size: 14px;
    color: #000000;
    white-space: nowrap;
    font-weight: 500;
}

.product-card3__price-new {
    color: #E31E24;
    font-weight: 500;
    white-space: nowrap;
    font-size: 24px;
}

.product-card3__btn-wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.product-card3__btn {
    margin-left: 20px;
}

.product-card3__btn-img {
    width: 23px;
    height: 23px;
}

.product-card3__btn-img--active {
    fill: #E31E24;
}

.product-card3__btn-img:hover {
    fill: #E31E24;
}
/* ===================== */
.product-card2--2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-width: 180px;
    max-width: 237px;
    background: #ffffff;
    border: 1px solid #E4E4E5;
    border-radius: 15px;
    min-height: 533px;
    transition: .3s;
}

.product-card2--2:hover {
    box-shadow: 5px 5px 30px rgb(0 0 0 / 55%);
    scale: 1.03;
}

.product-card2__img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    aspect-ratio: 9/12;
    cursor: pointer;
    object-fit: contain;
}

.product-card2__img-none {
    width: 100%;
    aspect-ratio: 9/12;
    object-fit: contain;
    padding: 10px;
    cursor: pointer;
    object-fit: contain;
}

.product-card2__img:hover {
    cursor: pointer;
}

.product-card2__text-wrap {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 16px;
}

.product-card2__title {
    font-size: 16px;
    color: #000000;
    font-weight: bold;
    text-align: center;
    padding: 0 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.product-card2__title:hover {
    cursor: pointer;
}

.product-card2__details {
    margin-top: 10px;
    padding: 0 10px;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    flex: 1 1;
}

.product-card2__bottom-wrap {
    display: flex;
    justify-content: space-between;
}

.product-card2__price-wrap {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: end;
    gap: 10px;
    padding-top: 10px;
    padding-right: 10px;
}

.product-card2__price {
    font-size: 24px;
    font-weight: 500;
    white-space: nowrap;
    color: #000000;
    margin-bottom: 33px;
}

.product-card2__price--discount {
    text-decoration: line-through;
    font-size: 14px;
    color: #000000;
    white-space: nowrap;
    font-weight: 500;
}

.product-card2__price-new {
    color: #E31E24;
    font-weight: 500;
    white-space: nowrap;
    font-size: 24px;
}

.product-card2__btn-wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 20px;
    margin-left: auto;
}
.product-card2__btn {
    margin-left: 20px;
}

.product-card2__btn-img {
    width: 23px;
    height: 23px;
}

.product-card2__btn-img--active {
    fill: #E31E24;
}

.product-card2__btn-img:hover {
    fill: #E31E24;
}
/* ===================== */
.product-card--1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 468px;
    min-width: 180px;
    max-width: 237px;
    background: #ffffff;
    transition: .3s;
}

.product-card--1:hover {
    box-shadow: 5px 5px 30px rgb(0 0 0 / 55%);
    scale: 1.03;
}

.product-card__img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    aspect-ratio: 9/12;
    border: 1px solid #E4E4E5;
    cursor: pointer;
    object-fit: contain;
}

.product-card__img-none {
    width: 100%;
    aspect-ratio: 9/12;
    border: 1px solid #E4E4E5;
    object-fit: contain;
    padding: 10px;
    cursor: pointer;
    object-fit: contain;
}

.product-card__img:hover {
    cursor: pointer;
}

.product-card__text-wrap {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex: 1 1;
    padding: 16px;
    border: 1px solid #E4E4E5;
    min-height: 137px;
}

.product-card__title {
    font-size: 16px;
    color: #000000;
    font-weight: bold;
    max-height: 44px;
    overflow: hidden;
}

.product-card__title:hover {
    cursor: pointer;
}

.product-card__details {
    margin-top: 10px;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    flex: 1 1;
}

.product-card__bottom-wrap {
    display: flex;
    justify-content: space-between;
}

.product-card__price-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    max-width: 117px;
    overflow: auto;
}

.product-card__price {
    font-size: 24px;
    font-weight: 500;
    white-space: nowrap;
    color: #000000;
}

.product-card__price--discount {
    text-decoration: line-through;
    font-size: 14px;
    color: #000000;
    white-space: nowrap;
    font-weight: 500;
}

.product-card__price-new {
    color: #E31E24;
    font-weight: 500;
    white-space: nowrap;
    font-size: 24px;
}

.product-card__btn-wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.product-card__btn {
    margin-left: 20px;
}

.product-card__btn-img {
    width: 23px;
    height: 23px;
}

.product-card__btn-img--active {
    fill: #E31E24;
}

.product-card__btn-img:hover {
    fill: #E31E24;
}