.user-purchases {
    flex: 1 1;
    padding-bottom: 40px;
}

.user-purchases--wrap {
}

.user-purchases__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 21px;
    margin-bottom: 21px;
    padding-left: 10px;
}

.user-purchases__header-count {
    display: flex;
    gap: 20px;
    align-items: center;
    color: #6B7A99;
    font-weight: 700;
}

.user-purchases__header-count-img {
    width: 40px;
    height: 40px;
}

.user-purchases__header-count-title {
    margin-bottom: 3px;
    white-space: nowrap;
}

.user-purchases__header-tab-wrap {
    margin-left: auto;
    margin-right: auto;
}
.user-purchases__header-tab-wrap .MuiTabs-flexContainer {
    flex-direction: column;
}

.user-purchases__filter {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    padding: 20px 0 20px 10px;
    margin-top: 15px;
    border-top: 2px solid #F2F3F5;
    border-bottom: 2px solid #F2F3F5;
}

.user-purchases__filter-type {
    width: 205px;
}


.user-purchases__filter-sort-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
    color: #6B7A99;
}

.ser-purchases__filter-sort-title {

}

.user-purchases__filter-sort-btn-wrap {
    padding: 3px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: .7;
    transition: .3s;
}

.user-purchases__filter-sort-btn-wrap--active {
    background: #a9b5c2;
}

.user-purchases__filter-sort-btn-wrap:hover {
    background: #a9b5c2;
}

.user-purchases__filter-sort-btn {
    display: inline-block;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.user-purchases__filter-date-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.user-purchases__filter-date-btn-wrap {
    display: flex;
    justify-content: end;
    gap: 4px;
}

.user-purchases__filter-date-btn {
    border: 2px solid black;
    background-color: white;
    padding: 4px 8px;
    font-size: 14px;
    cursor: pointer;
    border-color: rgba(0, 0, 0, 0.23);
    color: #6B7A99;
    margin-top: 3px;
    display: block;
    transition: .3s;
}

.user-purchases__filter-date-btn:hover {
    background-color: rgb(161, 157, 157);
    color: #fff;
}

.user-purchases__filter-date {
    display: flex;
    align-items: end;
    justify-content: end;
    gap: 5px;
    min-width: 185px;
    cursor: pointer;
    color: #6B7A99;
}

.user-purchases__filter-date-title {
    
}

.user-purchases__filter-date-select {

}

.user-purchases__filter-date-drop {
    position: absolute;
    top: -1500px;
    right: 0;
    z-index: -2;
    border: 1px solid;
}

.user-purchases__filter-date-drop-active {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 5;
}

.user-purchases__btn-download {
    display: flex;
    align-items: center;
    gap: 5px;
    background: #4C6FFF;
    color: #fff;
    border-radius: 6px;
    padding: 10px 15px;
    transition: .3s;
    margin-left: auto;
}

.user-purchases__btn-download:hover {
    background: #6B7A99;
}

.user-purchases__items {
    min-height: calc(100vh - 452px);
}

.user-purchases__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
    padding: 7px 0 7px 15px;
    border-bottom: 2px solid #F2F3F5;
    cursor: pointer;
    transition: .3s;
}

.user-purchases__item:hover {
    background: aliceblue;
}

.user-purchases__item--wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    flex-wrap: wrap;
    flex: 1 1;
}

.user-purchases__item:hover {
}

.user-purchases__item--left {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    margin-right: auto;
}

.user-purchases__item--right {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    margin-left: auto;
}

.user-purchases__item-name {
    width: 142px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.user-purchases__item-product-wrap {
    display: flex;
    align-items: center;
    gap: 5px;
}

.user-purchases__item-product-img {
    width: 55px;
    aspect-ratio: 9/12;
}

.user-purchases__item-name-wrap {

}

.user-purchases__item-name-title {
    font-weight: 600;
}

.user-purchases__item-product-name {
    font-size: 14px;
    width: 142px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.user-purchases__item-date-wrap {
    
}

.user-purchases__item-date-title {
    font-weight: 600;
}

.user-purchases__item-date {
    width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.user-purchases__item-status {
    border-radius: 5px;
    padding: 2px 10px 4px;
    width: 125px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-purchases__item-status--in_process {
    color: #4C6FFF;
    border: 1px solid #4C6FFF;
}
.user-purchases__item-status--done {
    color: #7FC008;
    border: 1px solid #7FC008;
}
.user-purchases__item-status--rejected {
    color: #DB303F;
    border: 1px solid #DB303F;
}

.user-purchases__item-product-category-wrap {
    width: 142px;
}

.user-purchases__item-product-category-title {
    font-weight: 600;
}

.user-purchases__item-product-category {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.user-purchases__item-exucutor-wrap {
    width: 142px;
}

.user-purchases__item-exucutor-title {
    font-weight: 600;
}

.user-purchases__item-exucutor {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    border: 1px solid #DFE2E9;
    border-radius: 8px;
    padding: 2px 5px;
    text-align: center;
}

.user-purchases__item-total-price-wrap {

}

.user-purchases__item-total-price-title {
    font-weight: 600;
}

.user-purchases__item-total-price {
    width: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.user-purchases__item-btn-wrap {
    display: flex;
    align-items: center;
    gap: 15px; 
    margin-left: auto;   
}

.user-purchases__item-stars {
    width: 25px;
    height: 25px;
    flex: 0 0 auto;
    cursor: pointer;
    transition: .3s;
}

.user-purchases__item-stars-is-favorite {
    fill: rgb(250, 15, 15);
}

.user-purchases__item-stars:hover {
    fill: rgb(250, 15, 15);
}

.user-purchases__error-text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    padding-top: 70px;
}

.user-product__disable {
    display: none;
    pointer-events: none;
    opacity: 0;
}

@media (min-width: 400px) {
    .user-purchases__filter {
        flex-direction: row;
        align-items: center;
    }
}

@media (min-width: 450px) {
    .user-purchases__header-tab-wrap .MuiTabs-flexContainer {
        flex-direction: row;
    }
}

@media (min-width: 576px) {
    .user-purchases__header {
        flex-direction: row;
        padding-left: 15px;
    }
    .user-purchases__filter {
        padding: 30px 0 30px 15px;
    }
}

@media (min-width: 768px) {
    .user-purchases__header {
        padding-left: 70px;
        padding-right: 70px;
    } 
}