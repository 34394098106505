.recovery-view {

}

.recovery-view__modal-input {
    padding: 15px 20px;
    width: 100%;
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 17px;
    color: #333;
    outline: none;
    border-radius: 8px;
    background: #f6f7fa;
    border: 1px solid #065bea;
}

.recovery-view--wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    gap: 15px;

}

.recovery-view__input-wrap {
    display: inline-block;
    padding: 25px;
    border: 5px solid #e3dede;
    box-shadow: 2px 3px 8px rgb(0 0 0 / 52%);
}

.recovery-view__input-title {
    font-size: 20px;
    font-weight: 700;
}

.recovery-view__password-error {
    color: red;
    margin-top: 4px;
    margin-bottom: 4px;
}

.recovery-view__btn {
    border: 2px solid black;
    background-color: white;
    padding: 6px 15px;
    font-size: 14px;
    cursor: pointer;
    border-color: #0d48ac;
    color: #0d48ac;
    display: block;
    transition: .3s;
    margin-top: 20px;
    margin-left: auto;
}

.recovery-view__btn:hover {
    background: #0d48ac;
    color: white;
}

.recovery-view__timer-wrap {
    
}

.recovery-view__timer-title {

}

.recovery-view__timer-end {
    color: red;
}

.recovery-view__timer {

}