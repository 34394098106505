.user-categories {
    padding-top: 15px;
    padding-bottom: 15px;
    flex: 1 1;
    background: #fff;
}

.user-categories__mod-edite-img--wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.user-categories__mod-edite-img-wrap {
    display: inline-block;
    border: 1px solid #E5E5E5;
    text-align: center;
}

.user-categories__mod-edite-img {
    border-top: 1px solid #E5E5E5;
    width: 100px;
    height: 100px;
}

.user-categories__mod-edite-category-create-img-text-wrap {

}

.user-categories__mod-edite-category-create-img {
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
}

.user-categories__mod-edite-category-create-img-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border: 3px dashed #E5E5E5;
    border-radius: 6px;
    max-width: 270px;
    height: 230px;
    text-align: center;
    padding: 5px 10px;  
    margin-left: auto;
    margin-right: auto;
}

.user-categories__mod-edite-category-create-img-link-upload-input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    top: 0; 
}

.user-categories__mod-edite-category-create-img-link-upload-lable {
    font-weight: 600;
    color: #4C6FFF;
    cursor: pointer;
}

.user-categories__mod-edite-category-lable {
    color: #344054;
    font-weight: 500;
    margin-top: 15px;
    margin-bottom: 5px;
}

.user-categories__mod-edite-category-input {
    margin-bottom: 40px !important;
}

.user-categories--wrpa {
}

.user-categories__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 21px;
    margin-bottom: 21px;
    padding-left: 10px;
}

.user-categories__header-count {
    display: flex;
    gap: 20px;
    align-items: center;
    color: #6B7A99;
    font-weight: 700;
}

.user-categories__btn-create {
    display: flex;
    align-items: center;
    gap: 5px;
    background: #4C6FFF;
    color: #fff;
    border-radius: 6px;
    padding: 10px 15px;
    margin-left: auto;
    transition: .3s;
}

.user-categories__btn-create:hover {
    background: #6B7A99;
}

.user-categories__btn-create-img {
    width: 28px;
    height: 28px;
}

.user-categories__category-btn-create-sub {
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 6px;
    padding: 10px 15px;
    transition: .3s;
    color: #7D8FB3;
    margin-left: auto;
    margin-right: auto;
}

.user-categories__category-btn-create-sub:hover {
    background: #6B7A99;
    color: #fff;
}

.user-categories__category-btn-create-sub-img {
    width: 28px;
    height: 28px;
}

.user-categories__category-btn-create-sub-text {
}

.user-categories__header-count-img {
    width: 30px;
    height: 30px;
}

.user-categories__header-count-title {
    margin-bottom: 3px;
    white-space: nowrap;
}

.user-categories__category-wrap {
    display: flex;
    gap: 20px;
    justify-content: start;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-left: 35px;
    padding-top: 25px;
    border-top: 2px solid #F2F3F5;
}

.user-categories__category {
    position: relative;
    padding: 45px 20px;
    max-width: 315px;
    width: 100%;
    border-radius: 5px;
    background: #FAFBFC;
}

.user-categories__category-img-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-categories__category-img {
    width: 100px;
    height: 100px;
    margin-bottom: 25px;
}

.user-categories__category-img-none {
    width: 100px;
    height: 100px;
    margin-bottom: 25px;
}

.user-categories__category-name {
    color: #6B7A99;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
}

.user-categories__category-btn-wrap {
    position: absolute;
    top: 20px;
    left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 6px;
}

.user-categories__category-btn--wrap {
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: .3s;
}

.user-categories__category-btn {
    width: 30px;
    height: 30px;
    transition: .3s;
}

.user-categories__category-btn-hide {
    
}
.user-categories__category-btn-edite {
    
}
.user-categories__category-btn-delete {
    
}

.user-categories__category-btn-delete--hover,
.user-categories__category-btn-edite--hover,
.user-categories__category-btn-hide--hover {
    display: none;
}

.user-categories__category-btn--wrap:hover .user-categories__category-btn-delete,
.user-categories__category-btn--wrap:hover .user-categories__category-btn-edite,
.user-categories__category-btn--wrap:hover .user-categories__category-btn-hide {
    display: none;
}

.user-categories__category-btn--wrap:hover .user-categories__category-btn-delete--hover,
.user-categories__category-btn--wrap:hover .user-categories__category-btn-edite--hover,
.user-categories__category-btn--wrap:hover .user-categories__category-btn-hide--hover {
    display: block;
}

.user-categories__category-btn:disabled {
    opacity: .5;
} 

.user-categories__category-sub-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
}

.user-categories__category-sub {
    box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
    border-radius: 15px;
    padding: 5px 15px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.user-categories__category-sub-name {
    color: #7D8FB3;
    font-weight: 600;
}

.user-categories__category-sub-btn-wrap {
    display: flex;
    gap: 6px;
}

@media (min-width: 576px) {
    .user-categories__header {
        flex-direction: row;
        padding-left: 15px;
    }
}

@media (min-width: 768px) {
    .user-categories__header {
        padding-left: 70px;
        padding-right: 70px;
    }  
}