.barcode-scanner {
    height: 100%;
    max-height: calc(100vh - 204px);
}

.barcode-scanner-none {
    height: 50px;
}

.barcode-scanner__btn-wrap {
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
}

.barcode-scanner__btn {
    display: inline-block;
    background: #4C6FFF;
    color: #fff;
    border-radius: 6px;
    padding: 12px 20px; 
    transition: .3s;
}

.barcode-scanner__btn:hover {
    background: #6B7A99;
}