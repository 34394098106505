.more-analytics2 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.more-analytics2--wrpa {

}

.more-analytics2__analytics-error {
    margin-top: 40px;
    text-align: center;
    font-size: 18px;
}
/* -------------- */
.more-analytics2__completed-wrap {
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 100%;
}

.more-analytics2__completed {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.more-analytics2__completed-title {
    font-size: 20px;
    text-align: start;
    margin-top: 10px;
    color: #686868;
    margin-bottom: 10px;
}

.more-analytics2__completed-sub-title {
    font-size: 18px;
    font-weight: 700;
    text-align: start;
}

.more-analytics2__completed-graph {
    width: 100%;
    height: 420px;
}

.more-analytics2__completed-graph-canvas {
     aspect-ratio: 1 / 1 !important;
}

.more-analytics2__completed-tabl {
    display: flex;
    gap: 5px;
    flex-direction: column;
    width: 100%;
    max-height: 428px;
    overflow: auto;
}

.more-analytics2__completed-tabl-item {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    padding: 2px 10px;
}

.more-analytics2__completed-tabl-item-info {
    width: 180px;
    white-space: nowrap;
}
/* -------visitorsYear------- */
.more-analytics2__visitors-year-wrap {
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 100%;
}

.more-analytics2__visitors-year {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.more-analytics2__visitors-year-title {
    font-size: 20px;
    text-align: start;
    margin-top: 10px;
    color: #686868;
    margin-bottom: 10px;
}

.more-analytics2__visitors-year-sub-title {
    font-size: 18px;
    font-weight: 700;
    text-align: start;
}

.more-analytics2__visitors-year-graph {
    width: 100%;
    height: 420px;
}
.more-analytics2__visitors-year-graph-canvas-ads {
    width: 100% !important;
}

.more-analytics2__visitors-year-graph-canvas {
     aspect-ratio: 1 / 1 !important;
}

.more-analytics2__visitors-year-tabl {
    display: flex;
    gap: 5px;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    max-height: 196px;
    overflow: auto;
}

.more-analytics2__visitors-year-tabl-item {
    display: flex;
    flex-wrap: wrap;
    width: 49%;
    gap: 15px;
    padding: 2px 10px;
    overflow: auto;
}

.more-analytics2__visitors-year-tabl-item-info {
}
/* ---------yearmanagers----------- */
.more-analytics2__year-managers-wrap {
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 100%;
}

.more-analytics2__year-managers-title {
    font-size: 20px;
    text-align: start;
    margin-top: 10px;
    color: #686868;
    margin-bottom: 10px;
}

.more-analytics2__year-managers-sub-title {
    font-size: 18px;
    font-weight: 700;
    text-align: start;
}

.more-analytics2__year-managers-tabl {
    display: flex;
    gap: 15px;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    max-height: 380px;
    overflow: auto;
}

.more-analytics2__year-managers-tabl-item {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 15px;
    overflow: auto;
}

.more-analytics2__year-managers-tabl-item-title {
    width: 100%;
    color: #686868;
    text-align: start;
}

.more-analytics2__year-managers-tabl-item-managers {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
}

.more-analytics2__year-managers-tabl-item-manager {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 5px;
    padding: 3px 10px;
}

.more-analytics2__year-managers-tabl-item-manager--dark {
    background: rgb(193, 188, 188);
}

.more-analytics2__year-managers-tabl-item-manager-data-wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 5px;
    width: 70%;
}

.more-analytics2__year-managers-tabl-item-manager-data {

}

/* -------------------------- */
.more-analytics2__accordion-result {
    width: 100%;
    margin-top: 15px !important;
    border: 1px solid #e3dede;
}