.heart-btn {
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.heart-btn__img {
    width: 20px;
}

.heart-btn__text {
    font-size: 14px;
    color: var(--colorHeaderText);
}

.heart-btn__count {
    position: absolute;
    top: -17px;
    right: -16px;
    background: var(--bgNewProductHeader);
    color: var(--colorNewProductHeader);
    border-radius: 50%;
    width: 23px;
    height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}
@media (min-width: 1080px) {
    .heart-btn__text {
        display: block;
        text-transform: uppercase;
        font-size: 11px;
    } 
    .heart-btn__count {
        right: -8px;
    }
}