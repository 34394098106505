.subscription-view {
    color: #101828;
}

.subscription-view__title-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 23px;
}

.subscription-view__title {
    font-weight: 600;
    font-size: 21px;
    line-height: 20px;
}

.subscription-view__btn-close {

}

.subscription-view__tabs--wrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

.subscription-view__tabs-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
    flex-direction: column;
    background: #EBEEF2;
    padding: 3px 4px;
    margin-bottom: 40px;
}

.subscription-view__tab {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #7D8FB3;
    width: 100%;
    cursor: pointer;
    padding: 10px 50px;
    white-space: nowrap;
}

.subscription-view__tab--active {
    background: #FFFFFF;
    border-radius: 100px;
    color: #101143;
}

.subscription-view__cards-wrap {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

.subscription-view__card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 370px;
    width: 100%;
    padding: 33px 29px;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 12px;
}

.subscription-view__card--wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.subscription-view__card-title-wrap {
    display: flex;
    gap: 25px;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.subscription-view__card-title {
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    color: #000;
}

.subscription-view__card-popular-wrap {
    display: flex;
    color: #fff;
    gap: 5px;
    align-items: center;
    background: #7FC008;
    border-radius: 6px;
    padding: 7px 13px;
}

.subscription-view__card-popular-img {
    width: 11px;
    height: 11px;
}

.subscription-view__card-price-wrap {
    margin-bottom: 26px;
}

.subscription-view__card-price {
    font-weight: 600;
    font-size: 38px;
    line-height: 42px;
    color: #000;
}

.subscription-view__info-item-wrap {
    border-top: 1px dashed #A9A9AA;
    padding-top: 28px;
}

.subscription-view__info-item-text-wrap {
    display: flex;
    gap: 5px;
    align-items: baseline;
    margin-bottom: 10px;
}

.subscription-view__info-item-text-img {
    width: 24px;
    height: 24px;
    transform: translateY(6px);
}

.subscription-view__info-item-text {
    font-size: 14px;
}

.subscription-view__card-btn-by {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 12px;
    background: #EBEEF2;
    border-radius: 6px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #7D8FB3;
    margin-top: 28px;
}

.subscription-view__card-btn-by--active {
    background: #4C6FFF;
    color: #fff;
}

.subscription-view__card-btn-close {
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #545D69;
    margin-top: 30px;
    margin-left: auto;
}

@media (min-width: 576px) {
    .subscription-view__tabs-wrap {
        border-radius: 100px;
        flex-direction: row;
    }
    .subscription-view__tab {
        min-width: 183px;
    }
}