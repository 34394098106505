.purchases-edite-arr {

}
.purchases-edite-arr--wrap {
    margin-top: 5px;
    margin-bottom: 5px;
}

.purchases-edite-arr__items {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.purchases-edite-arr__btn-delete-wrap {
    display: flex;
    gap: 20px;
    justify-content: center;
}

.purchases-edite-arr__btn-delete {
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 50%;
    padding: 3px;
    transition: .3s;
}

.purchases-edite-arr__btn-delete:hover {
    background-color: rgb(161, 157, 157);
}

.purchases-edite-arr-btn-wrap {
    display: flex;
    gap: 15px;
    align-items: flex-end;
    flex-wrap: wrap;
}
.purchases-edite-arr-btn {
    background: #4C6FFF;
    border-radius: 6px;
    color: #fff;
    width: 49px;
    height: 42px;
    transition: .3s;
}

.purchases-edite-arr-btn:hover {
    background: #6B7A99;
}