.user-analytics {
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 15px;
    width: 100%;
    flex: 1 1;
}

.user-analytics--wrpa {

}

.user-analytics__title {
    font-size: 16px;
    margin-bottom: 12px;
    margin-top: 20px;
    margin-left: 20px;
    color: rgba(43, 58, 75, 1);
}

.user-analytics__subtitle {
    font-size: 12px;
    margin-bottom: 12px;
    margin-left: 20px;
    color: rgba(133, 140, 148, 1);
}

.user-analytics__tab-wrap {
}

.user-analytics__tab-wrap .MuiButtonBase-root {
    /* min-height: 100px; */
    margin-top: 30px;
    padding: 5px 20px;
    font-size: 14px;
}

.user-analytics__tab-wrap .MuiTabs-flexContainer {
    display: flex;
    flex-direction: column;
}
/* -------------------------- */
@media (min-width: 576px) {
    .user-analytics {
        padding-top: 15px;
        padding-right: 15px;
        padding-left: 25px;
    }
    .user-analytics__title {
        font-size: 21px;
        margin-left: 20px;
    }
    .user-analytics__subtitle {
        font-size: 14px;
        margin-bottom: 12px;
        margin-left: 20px;
        color: #6D7580;
    }
    .user-analytics__tab-wrap {
        margin-left: 15px;
    }
    .user-analytics__tab-wrap .MuiTabs-flexContainer {
        flex-direction: row;
    }
}