.sign-up {
    margin: 0 auto;
    width: 100%;
}

.sign-up-wrap {
    min-height: 100vh;
}

.sign-up__swiper {
    background-color: #065bea;
    padding: 30px;
}

.sign-up__slider-wrap {

}

.sign-up__slider-img {

}

.sign-up__slider-title {
    text-align: center;
    font-size: 22px;
    text-transform: uppercase;
    margin-top: 15px;
    margin-bottom: 20px;
    color: #ffffff;
}

.sign-up__slider-text {
    text-align: center;
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 35px;
    color: #ffffff;
}

.mySwiper.sign-up__mySwiper .swiper-button-next {
    display: none;
}

.mySwiper.sign-up__mySwiper .swiper-button-prev {
    display: none;
}

.sign-up__mySwiper .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #ffffff;
}

.sign-up__form-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.sign-up__form {
    padding: 20px;
    height: 100%;
}

.sign-up__company-name {
    font-size: 30px;
    margin-top: 10px;
    margin-bottom: 22px;
    text-transform: uppercase;
}

.sign-up__form-title {
    font-size: 25px;
    margin-top: 10px;
    margin-bottom: 15px;
    text-align: center;
}

.sign-up__form-sub-title {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 15px;
}

.sign-up__form-sub-title-link {
    color: blue;
    transition: .3s;
}

.sign-up__form-sub-title-link:hover {
    scale: 1.05;
}

.sign-up__label {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.sign-up__input {
    padding: 15px 20px;
    width: 100%;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 17px;
    color: #333;
    outline: none;
    border-radius: 8px;
    background: #f6f7fa;
    border: 1px solid #065bea;
}

.sign-up__login-error {
    color: red;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
}

.sign-up__btn {
    width: 100%;
    background: #065bea;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    padding: 15px 0;
    outline: none;
    margin-top: 15px;
    margin-bottom: 40px;
    text-transform: uppercase;
    transition: .3s;
}

.sign-up__btn:hover {
    background-color: #333;
}

.sign-up__link-to-main {
    font-size: 18px;
    color: blue;
    transition: .3s;
}

.sign-up__link-to-main:hover {
    scale: 1.05;
}

@media (min-width: 768px) {
    .sign-up-wrap {
        display: flex;
    }
    .sign-up__swiper {
        width: 49%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .sign-up__form {
        padding: 0 20px;
    }

    .sign-up__form-wrap {
        width: 49%;
    }
}