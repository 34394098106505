.order-elements {
    display: flex;
    gap: 15px;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 10px;
}

.order-elements--wrap {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 500px;
    padding: 35px;
    border: 2px solid #F2F3F5;
    background: #FAFBFC;
}

[draggable="true"] {
    cursor: move;
}

.order-elements__label {
    display: flex;
    align-items: center;
    gap: 5px;
}

.order-elements__label-img {
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.order-elements__box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    padding: 10px 20px;
    font-size: 18px;
    background: #FFFFFF;
    box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);
    border-radius: 8px;
}

.order-elements__text {
    color: #344054;
}

.order-elements__btn-wrap {
    display: flex;
    align-items: center;
    gap: 10px;
    opacity: .7;
}

.order-elements__btn {
    width: 35px;
    height: 35px;
    cursor: pointer;
    border-radius: 50%;
    padding: 3px;
    transition: .3s;
}

.order-elements__btn:hover {
    background: rgb(205, 199, 199);
}

.order-elements__btn-up {
    transform: rotate(90deg);
}

.order-elements__btn-down {
    transform: rotate(-90deg);
}