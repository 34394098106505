.mobile-menu-landing-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile-menu-landing {
    cursor: pointer;
    margin: 0 auto;
    padding-left: 1.25em;
    position: relative;
    width: 33px;
    height: 25px;
  }

  .mobile-menu-landing__global {
    backface-visibility: hidden;
    position: absolute;
    left: 0;
    border-top: 3px solid #74BAE8;
    width: 100%;
    transition: 0.55s;
  }

  .mobile-menu-landing__top {
    top: 0;
  }

  .mobile-menu-landing__middle {
    top: 13px;
  }

  .mobile-menu-landing__bottom {
    top: 27px;
  }

  .mobile-menu-landing__top-active {
    backface-visibility: hidden;
    top: 15px;
    transform: rotate(50deg);
    transition: 0.55s 0.5s;
  }

  .mobile-menu-landing__middle-active {
    opacity: 0;
  }

  .mobile-menu-landing__bottom-active {
    backface-visibility: hidden;
    top: 15px;
    transform: rotate(-410deg);
    transition: 0.55s 0.5s;
  }
@media (min-width: 880px) {
  .mobile-menu-landing-wrap {
    display: none;
  }    
}