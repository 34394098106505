.input-passwor--wrap {
    display: flex;
    gap: 8px;
    flex-direction: column;
}

.input-passwor__label {
    font-size: 16px;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 10px;
}

.input-passwor__input {
    padding: 15px 20px;
    width: 100%;
    font-size: 14px;
    line-height: 17px;
    color: #333;
    outline: none;
    border-radius: 8px;
    background: #f6f7fa;
    border: 1px solid #065bea;
}