.user-settings {
    position: relative;
    padding-top: 15px;
    padding-bottom: 15px;
    min-height: calc(100vh - 85px);
    flex: 1 1;
}

.user-settings__password-error {
    color: red;
}

.user-settings--wrap {

}

.user-settings__title {
    text-align: center;
    font-size: 20px;
}

.user-settings__select-wrap {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.user-settings__select {
    display: flex;
    margin-left: 5px;
    align-items: center;
} 

.user-settings__select-btn-wrap {
    position: relative;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;
    margin-left: 5px;
    transition: .3s;
}

.user-settings__select-btn-wrap:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

.user-settings__select-btn {
    position: absolute;
    top: 4px;
    left: 7px;
    width: 12px;
    height: 12px;
    border-left: 2px solid #000000;
    border-bottom: 2px solid #000000;
    transform: rotate(-45deg);
}

.user-settings__select-btn--active {
    top: 9px;
    transform: rotate(135deg);
}

.user-settings__option-wrap {
    position: absolute;
    background: #ffffff;
    max-height: 350px;
    overflow: auto;
    display: flex;
    gap: 5px;
    flex-direction: column;
    right: 0;
    opacity: 0;
    text-align: end;
    transition: .3s;
    z-index: -2;
}

.user-settings__option-wrap--active {
    opacity: 1;
    z-index: 10;
}

.user-settings__option {
    cursor: pointer;
    transition: .3s;
    padding: 5px;
}

.user-settings__option:hover {
    background: rgba(0, 0, 0, 0.2);
}

.user-settings__item {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 20px;
    margin-bottom: 15px;
    margin-top: 15px;
    padding: 10px;
}

.user-settings__item-title-wrap {
    display: flex;
    gap: 15px;
}

.user-settings__item-title {
    font-weight: 700;
    font-size: 16px;
    width: 160px;
}

.user-settings__item-text {
}