.customer-card {

}

.customer-card__card {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    font-size: 12px;
    padding: 10px;
    cursor: pointer;
    transition: .3s;
    border-top: 2px solid #F2F3F5;
}

.customer-card__card:hover {
    background: #ebeef2;
}

.customer-card__checkbox-disabled {
    position: relative;
}

.customer-card__checkbox-disabled::after {
    content: 'X';
    color: #DB303F;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.customer-card__card-text-img-wrap {
    display: flex;
    gap: 12px;
    align-items: center;
    max-width: 200px;
    width: 100%;
    overflow: auto;
}

.customer-card__card-text-wrap {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    flex: 1 1;
}

.customer-card__card-text {
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    min-width: 170px;
    max-width: 170px;
    width: 100%;
    overflow: hidden;
}

.customer-card__card-title {
    font-weight: 500;
}

.customer-card__card-title-email {
    color: #4C6FFF;
}

.customer-card__card-text-img {
    width: 45px;
    height: 45px;
}

.customer-card__card-text-img-name {
    display: flex; 
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    color: #4C6FFF;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    background: #E0E6FF;
    border-radius: 50%;
    text-transform: uppercase;
}

.customer-card__card-text-name-wrap {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.customer-card__card-text-name {
    color: #16192C;
    font-weight: 600;
    font-size: 14px;
}

.customer-card__card-text-count-wrap {
    display: flex;
    align-items: center;
    gap: 3px;
    color: #6B7A99;
    font-size: 13px;
    font-weight: 400;
}

.customer-card__card-text-count {
}

.customer-card__card-btn {
    color: #DB303F;
    font-size: 14px;
    font-weight: 400;
    transition: .3s;
}

.customer-card__card-btn-no-ban {
    color: #4C6FFF;
}

@media (min-width: 576px) {
    .customer-card__card {
        flex-wrap: nowrap;
        font-size: 16px;
    }
}