.landing-view {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.landing__registration {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 64px;
    font-weight: bold;
    box-shadow: 0 1px 3px rgb(0 0 0 / 0%);
    justify-content: center;
    background: rgb(255, 242, 0);
    padding: 15px;
}

.landing__registration-title {
    text-align: center;
}

.landing__registration-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #00a8db;
    color: white;
    border-radius: 4px;
    font-size: 14px;
    padding: 0px 10px;
    margin-left: 3vmin;
    height: 30px;
    font-weight: bold;
}

.landing__main-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 600px;
    background: #000000;
    background-image: linear-gradient(92.71deg, #33CEFF 3.48%, #6884E6 45.5%, #C054FF 95.45%);
    background-position: center;
    background-size: cover;
    background-clip: content-box;
    background-repeat: no-repeat;
}

.landing__main {
    text-align: center;
}

.landing__main-title {
    color: #ffffff;
    font-size: 30px;
    font-weight: 500;
    text-align: center;
}

.landing__main-sub-title {
    color: #ffffff;
    font-size: 22px;
    text-align: center;
}

.landing__main-btn {
    display: inline-block;
    margin: 20px auto;
    color: #102245;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.5px;
    border-width: 1px;
    border-radius: 10px;
    background-color: #fcdf37;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    padding: 18px 40px;
    transition: .3s;
}

.landing__main-btn:hover {
    background-color: rgba(0, 0, 0, 0.597);
    color: #fff;
}

.landing__main-sub-text {
    color: #ffffff;
    font-size: 16px;
    text-align: center;
}

.landing__example-wrap {
    width: 100%;
    background-image: linear-gradient(to bottom, rgba(4,190,254,0), rgba(68,129,235,0));
}

.landing__example {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    padding-bottom: 25px;
}

.landing__example-notebook-img {
    /* position: absolute;
    top: 3px; */
    /* left: 4px; */
    /* width: 300px; */
    width: 100%;
    height: auto;
    object-fit: contain;
}

.landing__example-tablet-wrap {
    display: flex;
    gap: 10px;
}

.landing__example-tablet-img {
    /* position: absolute;
    top: 201px;
    left: 10px; */
    width: 60%;
    height: auto;
    object-fit: contain;
}

.landing__example-phone-img {
    /* position: absolute;
    top: 201px;
    left: 180px; */
    z-index: 3;
    width: 30%;
    height: auto;
    object-fit: contain;
}

.landing__example-devices-title {
    text-align: center;
    color: #000000;
    font-size: 20px;
    opacity: 0.25;
    margin-bottom: 10px;
}

.landing__example-devices-img {
    margin: 0 auto;
    max-width: 280px;
    max-height: 24px;
}

.landing__advantage-wrap {
    width: 100%;
    background-color: #fff;
}

.landing__advantage {

}

.landing__advantage-title {
    font-size: 32px;
    text-align: center;
    margin-top: 10vmin;
    margin-bottom: 5vmin;
    color: #102245;
    letter-spacing: 2px;
}

.landing__advantage-item {
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-bottom: 40px;
}

.landing__advantage-item--reverse {
    flex-direction: column;
}

.landing__advantage-item-1 {
    width: 100%;
    height: 60vmin;
    background-image: url('../../assets/images/administrators.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.landing__advantage-item-2 {
    width: 100%;
    height: 60vmin;
    background-image: url('../../assets/images/clients.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.landing__advantage-item-3 {
    width: 100%;
    height: 60vmin;
    background-image: url('../../assets/images/managers.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.landing__advantage-item-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: max-content;
    padding: 10vmin;
}

.landing__advantage-item-info-sub-title {
    color: #102245;
    font-size: 16px;
    line-height: 1.55;
    opacity: 0.5;
}

.landing__advantage-item-info-title {
    color: #102245;
    font-size: 32px;
    font-weight: bold;
    line-height: 1.55;
    letter-spacing: 2.5px;
}

.landing__advantage-item-info-text {
    color: #102245;
    font-size: 16px;
    opacity: 0.6;
}

.landing__screen-wrap {
    width: 100%;
    margin-top: 150px;
    margin-bottom: 100px;
}

.landing__screen {
    text-align: center;
}

.landing__screen-title {
    color: #102245;
    font-size: 30px;
    font-weight: 500;
}

.landing__screen-sub-title {
    color: #8390aa;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 30px;
}

.landing__screen-img {
    background-image: url('../../assets/images/manage_desk.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 100%;
    height: 50vmin;
}

.landing__response-wrap {
    background-color: #f6f8fa;
    width: 100%;
    padding-bottom: 50px;
}

.landing__response-title {
    color: #102245;
    font-size: 30px;
    text-align: center;
    padding-top: 60px;
    padding-bottom: 60px;
}

.landing__response-content {
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
    background: #ffffff;
    margin-bottom: 40px;
}

.landing__response-content-img {
    background-image: url('../../assets/images/woman.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 470px;
}

.landing__response-content-text {
    padding: 20px;
}

.landing__response-content-text-title {
    color: #102245;
    font-size: 35px;
    font-weight: 500;
    margin-bottom: 20px;
}

.landing__response-content-text-list {
    list-style: disc;
    padding-left: 25px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
}

.landing__response-content-text-list-li {
    font-size: 16px;
    color: #102245;
    list-style: disc;
}

.landing__response-content-line {
    width: 100%;
    height: 1px;
    background-color: #e3e6e8;
    margin-bottom: 20px;
}

.landing__response-content-comment {
    color: #8390aa;
    font-size: 18px;
    font-style: italic;
    margin-bottom: 20px;
}

.landing__response-content-text-name {
    font-weight: 600;
}

.landing__response-content-text-vacancy {
    color: #8390aa;
    font-size: 17px;
}

.landing__response-benefits-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
}

.landing__response-benefits-img1 {
    width: 70px;
    height: 70px;
    border-radius: 8px;
    background-color: #ffbb00;
    padding: 5px;
    margin-bottom: 25px;
    margin-top: 20px;
    box-shadow: 2px 1px 11px rgb(0 0 0 / 52%);
}

.landing__response-benefits-img2 {
    width: 70px;
    height: 70px;
    border-radius: 8px;
    background-color: #2cd65a;
    padding: 5px;
    margin-top: 20px;
    margin-bottom: 25px;
    box-shadow: 2px 1px 11px rgb(0 0 0 / 52%);
}

.landing__response-benefits-title {
    color: #102245;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 10px;
}

.landing__response-benefits-list {
    display: flex;
    flex-direction: column;
    gap: 10px; 
    margin-left: 20px;
}

.landing__response-benefits-list-li {
    color: #102245;
    font-size: 18px;
    opacity: .6;
    list-style: disc;
}

.landing__possibilities-wrap {
    width: 100%;
    padding-top: 100px;
    padding-bottom: 60px;
}

.landing__possibilities {
    text-align: center;
}

.landing__possibilities-title {
    text-align: center;
    color: #102245;
    font-size: 40px;
    padding-bottom: 80px;
}

.landing__possibilities-items {
    display: flex;
    justify-content: space-around;
    gap: 5px;
    flex-wrap: wrap;
}

.landing__possibilities-item-wrap {
    display: flex;
    justify-content: space-around;
    gap: 15px;
    min-width: 49%;
    margin-bottom: 10px;
}

.landing__possibilities-item {
    display: inline-block;
    max-width: 110px;
}

.landing__possibilities-item-img {
    width: 64px;
    height: 64px;
    border-radius: 10px;
    padding: 5px;
    margin: 0 auto 10px;
}

.landing__possibilities-item-text {
    color: #000000;
    font-size: 20px;
    text-align: center;
}

.landing__possibilities-item-img--1 {
    background-color: #2cd65a;
    box-shadow: 0px 8px 10px -4px rgb(44 214 90 / 60%);
}

.landing__possibilities-item-img--2 {
    background-color: #ffbe26;
    box-shadow: 0px 8px 10px -4px rgb(255 190 38 / 60%);
}

.landing__possibilities-item-img--3 {
    background-color: #f134f7;
    box-shadow: 0px 8px 10px -4px rgb(241 52 247 / 50%);
}

.landing__possibilities-item-img--4 {
    background-color: #2bd6b4;
    box-shadow: 0px 8px 10px -4px rgb(43 214 180 / 60%);
}

.landing__possibilities-item-img--5 {
    background-color: #6448f2;
    box-shadow: 0px 8px 10px -4px rgb(100 72 242 / 60%);
}

.landing__possibilities-item-img--6 {
    background-color: #04befe;
    box-shadow: 0px 8px 10px -4px rgb(4 190 254 / 60%);
}

.landing__possibilities-btn {
    display: inline-block;
    color: #ffffff;
    font-size: 20px;
    font-weight: 500;
    border-width: 1px;
    border-radius: 8px;
    background-color: #2cd65a;
    padding: 13px 53px;
    margin-top: 80px;
    transition: .3s;
}

.landing__possibilities-btn:hover {
    background-color: rgba(0, 0, 0, 0.597);
    color: #fff;
}

.landing__partners-wrap {
    width: 100%;
    padding-top: 100px;
    padding-bottom: 60px;
    background-color: #f6f8fa;
}

.landing__partners-title {
    font-size: 40px;
    text-align: center;
    margin-bottom: 85px;
}

.landing__partners-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 15px;
    margin-bottom: 40px;
}

.landing__partners-item {
    position: relative;
    width: 300px;
    height: 110px;
}

.landing__partners-item-img-1 {
    border-radius: 8px;
    object-fit: fill;
    width: 100%;
    height: 110px;
    background-image: url('../../assets/images/clothing.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    filter: brightness(80%);
}

.landing__partners-item-img-2 {
    border-radius: 8px;
    object-fit: fill;
    width: 100%;
    height: 110px;
    background-image: url('../../assets/images/cosmetics.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    filter: brightness(80%);
}

.landing__partners-item-img-3 {
    border-radius: 8px;
    object-fit: fill;
    width: 100%;
    height: 110px;
    background-image: url('../../assets/images/accessories.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    filter: brightness(80%);
}

.landing__partners-item-img-4 {
    border-radius: 8px;
    object-fit: fill;
    width: 100%;
    height: 110px;
    background-image: url('../../assets/images/appliance.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    filter: brightness(70%);
}

.landing__partners-item-text {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    font-size: 28px;
    color: #e9e9e9;
}

.landing__partners-sub-title {
    text-align: center;
    color: #8390aa;
    font-size: 30px;
    margin-bottom: 25px;
}

.landing__partners-logo-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.landing__partners-logo {
    width: 120px;
    height: 40px;
    opacity: .65;
    object-fit: contain;
}

.landing__rating-wrap {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 80px;
}

.landing__rating-info-wrap {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.landing__rating-img {
    width: 100%;
    max-height: 530px;
    min-height: 300px;
    height: 100%;
}

.landing__rating-info-title {
    color: #102245;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 20px;
}

.landing__rating-info-sub-title {
    font-size: 24px;
}

.landing__rating-info-text {
    color: #8390aa;
    font-size: 18px;
    margin-bottom: 20px;
}

.landing__rating-info-cards {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
}

.landing__rating-info-card {
    border-radius: 8px;
    background-color: #f6f8fa;
    padding: 10px 12px 35px;
}

.landing__rating-info-card-header {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.landing__rating-info-card-img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
}

.landing__rating-info-card-title {
    font-size: 18px;
    margin-top: 5px;
    margin-bottom: 3px;
}

.landing__rating-info-card-sub-title {
    color: #8390aa;
    font-size: 14px;
}

.landing__rating-info-card-text {
    font-size: 18px;
}

.landing__rating-star {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 40px;
}

.landing__rating-star-img-wrap {
    max-width: 100%;
}

.landing__rating-star-img-text {
    color: #102245;
    font-size: 85px;
    font-weight: 500;
}

.landing__rating-star-img {
    width: 250px;
    height: 45px;
}

.landing__rating-star-text-title {
    color: #102245;
    font-size: 32px;
    margin-bottom: 20px;
    text-align: center;
}

.landing__rating-star-text {
    color: #8390aa;
    font-size: 18px;
    text-align: center;
}

@media (min-width: 576px) {
    .landing__main-wrap {
        height: 830px;
    }

    .landing__main-title {
        font-size: 60px;
    }

    .landing__main-sub-title {
        font-size: 32px;
    }

    .landing__partners-title {
        font-size: 32px;
    }

    .landing__rating-info-title {
        font-size: 22px;
    }

    .landing__rating-info-cards {
        flex-direction: row;
    }

    .landing__rating-info-wrap {
        flex-direction: row;
        flex-wrap: wrap;
    }

    .landing__rating-info {
        width: 49%;
    }

    .landing__rating-img {
        width: 47%;
        order: -1;
    }
}
  
@media (min-width: 768px) {
    .landing__example {
        justify-content: end;
        height: 400px
    }

    .landing__example-notebook-img {
        position: absolute;
        top: -106px;
        left: 4px;
        width: 488px;
    }

    .landing__example-tablet-img {
        position: absolute;
        top: -97px;
        left: 543px;
        width: 235px;
    }

    .landing__example-phone-img {
        position: absolute;
        top: -150px;
        left: 460px;
        width: 170px;
    }

    .landing__advantage-item {
        flex-direction: row;
    }

    .landing__advantage-item-1 {
        width: 50%;
        height: 60vmin;
        background-image: url('../../assets/images/administrators.png');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .landing__advantage-item-2 {
        width: 50%;
        height: 60vmin;
        background-image: url('../../assets/images/clients.png');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .landing__advantage-item-3 {
        width: 50%;
        height: 60vmin;
        background-image: url('../../assets/images/managers.png');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }
    
    .landing__advantage-item--reverse {
        flex-direction: row-reverse;
    }

    .landing__advantage-item-info {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .landing__advantage-item-info-title {
        font-size: 44px;
    }

    .landing__advantage-item-info-text {
        font-size: 20px;
    }

    .landing__screen-title {
        font-size: 44px;
    }

    .landing__screen-sub-title {
        font-size: 22px;
    }

    .landing__response-title {
        font-size: 40px;
    }

    .landing__response-content {
        flex-direction: row;
    }

    .landing__response-content-text-title {
        font-size: 40px;
    }

    .landing__response-content-text-list-li {
        font-size: 18px;
    }

    .landing__response-content-comment {
        font-size: 20px;
    }

    .landing__response-content-img {
        width: 35%;
    }

    .landing__response-benefits-wrap {
        flex-direction: row;
    }

    .landing__partners-title {
        font-size: 40px;
    }

    .landing__rating-info-title {
        font-size: 28px;
    }

    .landing__rating-star {
        flex-direction: row;
        gap: 30px;
    }
    
    .landing__rating-star-img-wrap {
        max-width: 49%;
    }

    .landing__rating-star-text-wrap {
        width: 50%;
    }

    .landing__rating-star-text-title {
        text-align: start;
    }

    .landing__rating-star-text {
        text-align: start;
    }
}

@media (min-width: 960px) {
    .landing__example-notebook-img {
        left: 74px;
    }

    .landing__example-tablet-img {
        left: 613px;
    }

    .landing__example-phone-img {
        left: 530px;
    }

    .landing__rating-star-text-title {
        font-size: 44px;
    }

    .landing__rating-star-text {
        font-size: 24px;
    }
}
  
@media (min-width: 1080px) {
    .landing__example-notebook-img {
        left: 154px;
    }

    .landing__example-tablet-img {
        left: 693px;
    }

    .landing__example-phone-img {
        left: 610px;
    }

    .landing__rating-info-title {
        font-size: 28px;
    }
   
}