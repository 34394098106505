.partners-view-wrap {
    min-height: calc(100vh - 55px);
    margin-top: 45px;
    margin-bottom: 10px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.partners-view {
    font-size: 12px;
}

.partners-view__main {
    position: relative;
    text-align: center;
    padding: 100px 0;
    margin-top: 50px;
    margin-bottom: 50px;
}

.partners-view__main-text-wrap {
    position: relative;
    z-index: 2;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.partners-view__main-text-title {
    font-size: 30px;
    margin-bottom: 15px;
}

.partners-view__main-text {
    font-size: 16px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}

.partners-view__main-text-btn {
    display: flex;
    align-items: end;
    justify-content: center;
    gap: 5px;
    font-size: 20px;
    color: #2EBFFF;
    transition: .3s;
}

.partners-view__main-text-btn:hover .partners-view__main-text-btn-img{
    transform: translateX(+10px);
}

.partners-view__main-text-btn-img {
    width: 20px;
    stroke: #2EBFFF;
    transition: .3s;
}

.partners-view__main-img--wrap {
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.partners-view__main-img-wrap {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    width: 100%;
    height: 100%;
}

.partners-view__main-img-0 {
    /* position: absolute; */
    top: 0;
    left: 15%;
}
.partners-view__main-img-1 {
    /* position: absolute; */
    top: 0;
    right: 15%;
}
.partners-view__main-img-2 {
    /* position: absolute; */
    top: 36%;
    left: 2%;
    z-index: 3;
}
.partners-view__main-img-3 {
    /* position: absolute; */
    top: 36%;
    right: 2%;
    z-index: 3;
}
.partners-view__main-img-4 {
    /* position: absolute; */
    top: 70%;
    left: 15%;
}
.partners-view__main-img-5 {
    /* position: absolute; */
    top: 70%;
    right: 15%;
}

.partners-view__main-img-logo {
    width: 150px;
    height: 85px;
    object-fit: contain;
}

.partners-view__banner-wrap {
    padding-top: 100px;
    padding-bottom: 100px;
    /* background: #fff; */
    background: #f6f8fa;
}

.partners-view__banner {
    width: 100%;
    font-size: 16px;
    text-align: center;
    font-weight: 700;
}

.partners-view__comments-wrap {
    /* background: #f6f8fa; */
    padding-top: 40px;
    padding-bottom: 40px;
}

.partners-view__comments {

}

.partners-view__comments-title {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
}

.partners-view__comment {
    background: #f0f1f6;
    border-radius: 25px;
    padding: 15px;
    margin-bottom: 15px;
}

.partners-view__comment-avatar-wrap {
    display: flex;
    gap: 3px;
    align-items: flex-end;
    color: #86878c;
}

.partners-view__comment-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.partners-view__comment-microphone {
    width: 20px;
    height: 20px;
}

.partners-view__comment-name {
    font-weight: 700;
}

.partners-view__comment-text {
    margin-top: 5px;
    margin-bottom: 7px;
}

.partners-view__comment-date--wrap {
    display: flex;
    gap: 10px;
    justify-content: space-between;
}

.partners-view__comment-date-wrap {
    display: flex;
    gap: 15px;
}

.partners-view__comment-date {

}

.partners-view__comment-like-wrap {
    display: flex;
    gap: 3px;
    align-items: center;
}

.partners-view__comment-like-number {
    font-size: 20px;
    font-weight: 700;
    color: #86878c;
}

.partners-view__comment-like {

}

.partners-view__shops-wrap {
    padding-top: 25px;
    padding-bottom: 25px;
    background: #f6f8fa;
}

.partners-view__shops {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.partners-view__shop {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    background-color: #eee;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    transition: .3s;
    box-shadow: 2px 1px 11px rgb(0 0 0 / 52%);
}

.partners-view__shop:hover {
    box-shadow: 5px 4px 6px rgb(0 0 0 / 92%);
}

.partners-view__shop-logo {
    width: 150px;
    height: 85px;
    object-fit: contain;
}

.partners-view__shop-info {
  
}

.partners-view__shop-info-text-name {
    font-size: 18px;
    font-weight: 700;
}

.partners-view__shop-info-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

@media (min-width: 500px) {
 
}

@media (min-width: 576px) {
    .partners-view {
        font-size: 16px;
    }
    .partners-view__shop {
        flex-wrap: nowrap;
    }
    .partners-view__banner {
        width: 75%;
        font-size: 22px;
    }
    .partners-view__main-text {
        font-size: 18px;
        width: 65%;
    }
}

@media (min-width: 768px) {
    .partners-view__main-text-title {
        font-size: 45px;
    }
    .partners-view__main-img--wrap {
        position: absolute;
    }

    .partners-view__main-img-0,
    .partners-view__main-img-1,
    .partners-view__main-img-2,
    .partners-view__main-img-3,
    .partners-view__main-img-4,
    .partners-view__main-img-5 {
        position: absolute;
    }

    .partners-view__main {
        padding: 200px 0;
    }

    .partners-view__main-text-wrap {
        width: 60%;
    }

    .partners-view__banner {
        font-size: 30px;
    }
}