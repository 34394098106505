.product-details-view-wrap {
    padding-top: 25px;
}

.product-details-view {

}

.product-details-view__wrap {
    position: relative;
}

.product-details-view__swiper-wrap {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    width: 350px;
}

.product-details-view__swiper {
    padding-left: 45px !important;
    padding-right: 45px !important;
}

.product-details-view__swiper-wrap .swiper-slide {
    aspect-ratio: 9/12;
}

.product-details-view__swiper-wrap .swiper-button-next {
    top: 50% !important;
    right: 10px !important;
    width: 35px;
    height: 35px;
    transform: rotate(180deg);
}

.product-details-view__swiper-wrap .swiper-button-prev {
    top: 50% !important;
    left: 10px !important;
    width: 35px;
    height: 35px;
}

.product-details-view__swiper-wrap .swiper-button-next::after {
    content: '' !important;
    background-image: url(../../assets/images/arrowCircle.svg);
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    transition: .3s;
} 

.product-details-view__swiper-wrap .swiper-button-prev::after {
    content: '' !important;
    background-image: url(../../assets/images/arrowCircle.svg);
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    transition: .3s;
} 

.product-details-view__swiper-wrap .swiper-button-next:hover::after {
    transform: translateX(-10px);
}

.product-details-view__swiper-wrap .swiper-button-prev:hover::after {
    transform: translateX(-10px);
}

.product-details-view__swiper-img {
    object-fit: contain;
}

.product-details-view__swiper-img-none {
    height: 100%;
    aspect-ratio: 9/12;
    padding: 15px;
    object-fit: contain;
}

.product-details-view__info-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 6px;
    background: #F5F5F5;
}

.product-details-view__info-title {
    font-size: 17px;
    font-weight: 700;
}

.product-details-view__info-text {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.product-details-view__edite-btn-wrap {
    display: flex;
    justify-content: end;
    margin-bottom: 20px;
}

.product-details-view__edite-btn {
    display: inline-block;
    background: #4C6FFF;
    color: #fff;
    border-radius: 6px;
    padding: 12px 20px; 
    transition: .3s;
}

.product-details-view__edite-btn:hover {
    background: #6B7A99;
}