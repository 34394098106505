.swiper-cards {
    padding-top: 32px;
    padding-bottom: 32px;
}

.swiper-cards--wrap {
    overflow: hidden;
}

.swiper-cards__title-wrap {
    position: relative;
}

.swiper-cards__title {
    font-size: 26px;
    line-height: 1.2;
    text-transform: uppercase;
    margin-bottom: 41px;
}

.mySwiper-swiper {
    padding-left: 18px;
    padding-bottom: 35px;
}

.mySwiper-swiper-type-3 {
    padding-bottom: 117px;
}

.swiper-cards--wrap.container .swiper {
    position: relative;
    overflow: visible;
}

.swiper-backface-hidden .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
}

.swiper-button-prev {
    top: -30px;
    left: auto;
    right: 86px;
}

.swiper-button-prev::after {
    content: "\2190" !important;
    width: 36px;
    height: 36px;
    transition: .3s;
    color: var(--colorMainText);
}

.swiper-button-prev:hover::after {
    transform: translateX(-10px);
}

.swiper-button-next {
    top: -30px;
    right: 23px;
}

.swiper-button-next::after {
    content: "\2192" !important;
    width: 36px;
    height: 36px;
    transition: .3s;
    color: var(--colorMainText);
}

.swiper-button-next:hover::after {
    transform: translateX(10px);
}

@media (min-width: 576px) {
    .swiper-cards__title {
        font-size: 40px;
    } 
}